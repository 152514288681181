import React, { useState, useEffect } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponent from '../components/MenuComponent';
import { useNavigate } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { IoAddCircleOutline, IoSearch } from "react-icons/io5";
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { getClientes, getClienteById, createCliente, updateCliente } from '../services/clientesService';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Select from 'react-select';

export interface Cliente {
  id: number;
  nome_fantasia: string;
  razao_social: string;
  cpf_cnpj: string;
  inscricao_estadual: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento?: string;
  bairro: string;
  cidade: string;
  uf: string;
  telefone_fixo?: string;
  celular: string;
  email: string;
  dados_bancarios?: string;
  banco?: string;
  agencia?: string;
  conta?: string;
  titular_conta?: string;
  cpf_cnpj_titular?: string;
  tipo_pessoa: string;
  tipo_cliente: number;
  tipo_fornecedor: number;
  tipo_transportador: number;
  antt?: string;
  maxlength?: number;
}

interface Municipio {
    cod_municipio: number;
    nome_municipio: string;
    estado: string;
}

const ClientesPage: React.FC = () => {
  
  const [municipios, setMunicipios] = useState<Municipio[]>([]);
  const [ufComboSel, setUfComboSel] = useState('');
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [newCliente, setNewCliente] = useState<Cliente>({
    id: 0,
    nome_fantasia: '',
    razao_social: '',
    cpf_cnpj: '',
    inscricao_estadual: '',
    cep: '',
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: '',
    celular: '',
    email: '',
    tipo_pessoa: 'F',
    tipo_cliente: 0,
    tipo_fornecedor: 0,
    tipo_transportador: 0,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [alerta, setAlerta] = useState('');
  const [search, setSearch] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('CPF');
  const [razaoSocialNome, setRazaoSocialNome] = useState('Nome');
  const [nomeFantasiaApelido, setNomeFantasiaApelido] = useState('Apelido');
  const navigate = useNavigate();

  const handleClose = () => setShow(false);

  const bancoOptions = [
    { value: '743', label: '743 - Banco Semear S.A' },
    { value: '100', label: '100 - Planner Corretora De Valores S.A' },
    { value: '096', label: '096 - Banco B3 S.A' },
    { value: '747', label: '747 - Banco Rabobank Internacional Do Brasil S.A' },
    { value: '748', label: '748 - Sicredi S.A' },
    { value: '752', label: '752 - Bnp Paribas Brasil S.A' },
    { value: '091', label: '091 - Unicred Central Rs' },
    { value: '399', label: '399 - Kirton Bank' },
    { value: '108', label: '108 - Portocred S.A' },
    { value: '757', label: '757 - Banco Keb Hana Do Brasil S.A' },
    { value: '102', label: '102 - Xp Investimentos S.A' },
    { value: '348', label: '348 - Banco Xp S/A' },
    { value: '340', label: '340 - Super Pagamentos S/A (Superdital)' },
    { value: '364', label: '364 - Gerencianet Pagamentos Do Brasil' },
    { value: '084', label: '084 - Uniprime Norte Do Paraná' },
    { value: '180', label: '180 - Cm Capital Markets Cctvm Ltda' },
    { value: '066', label: '066 - Banco Morgan Stanley S.A' },
    { value: '015', label: '015 - Ubs Brasil Cctvm S.A' },
    { value: '143', label: '143 - Treviso Cc S.A' },
    { value: '062', label: '062 - Hipercard Bm S.A' },
    { value: '074', label: '074 - Bco. J.Safra S.A' },
    { value: '099', label: '099 - Uniprime Central Ccc Ltda' },
    { value: '025', label: '025 - Banco Alfa S.A.' },
    { value: '075', label: '075 - Bco Abn Amro S.A' },
    { value: '040', label: '040 - Banco Cargill S.A' },
    { value: '190', label: '190 - Servicoop' },
    { value: '063', label: '063 - Banco Bradescard' },
    { value: '191', label: '191 - Nova Futura Ctvm Ltda' },
    { value: '064', label: '064 - Goldman Sachs Do Brasil Bm S.A' },
    { value: '097', label: '097 - Ccc Noroeste Brasileiro Ltda' },
    { value: '016', label: '016 - Ccm Desp Trâns Sc E Rs' },
    { value: '012', label: '012 - Banco Inbursa' },
    { value: '003', label: '003 - Banco Da Amazonia S.A' },
    { value: '060', label: '060 - Confidence Cc S.A' },
    { value: '037', label: '037 - Banco Do Estado Do Pará S.A' },
    { value: '159', label: '159 - Casa Credito S.A' },
    { value: '172', label: '172 - Albatross Ccv S.A' },
    { value: '085', label: '085 - Coop Central Ailos' },
    { value: '114', label: '114 - Central Cooperativa De Crédito No Estado Do Espírito Santo' },
    { value: '036', label: '036 - Banco Bbi S.A' },
    { value: '394', label: '394 - Banco Bradesco Financiamentos S.A' },
    { value: '004', label: '004 - Banco Do Nordeste Do Brasil S.A.' },
    { value: '320', label: '320 - Banco Ccb Brasil S.A' },
    { value: '189', label: '189 - Hs Financeira' },
    { value: '105', label: '105 - Lecca Cfi S.A' },
    { value: '076', label: '076 - Banco Kdb Brasil S.A.' },
    { value: '082', label: '082 - Banco Topázio S.A' },
    { value: '286', label: '286 - Ccr De Ouro' },
    { value: '093', label: '093 - Pólocred Scmepp Ltda' },
    { value: '273', label: '273 - Ccr De São Miguel Do Oeste' },
    { value: '157', label: '157 - Icap Do Brasil Ctvm Ltda' },
    { value: '183', label: '183 - Socred S.A' },
    { value: '014', label: '014 - Natixis Brasil S.A' },
    { value: '130', label: '130 - Caruana Scfi' },
    { value: '127', label: '127 - Codepe Cvc S.A' },
    { value: '079', label: '079 - Banco Original Do Agronegócio S.A' },
    { value: '081', label: '081 - Bbn Banco Brasileiro De Negocios S.A' },
    { value: '118', label: '118 - Standard Chartered Bi S.A' },
    { value: '133', label: '133 - Cresol Confederação' },
    { value: '121', label: '121 - Banco Agibank S.A' },
    { value: '083', label: '083 - Banco Da China Brasil S.A' },
    { value: '138', label: '138 - Get Money Cc Ltda' },
    { value: '024', label: '024 - Bco Bandepe S.A' },
    { value: '095', label: '095 - Banco Confidence De Câmbio S.A' },
    { value: '094', label: '094 - Banco Finaxis' },
    { value: '276', label: '276 - Senff S.A' },
    { value: '137', label: '137 - Multimoney Cc Ltda' },
    { value: '092', label: '092 - Brk S.A' },
    { value: '047', label: '047 - Banco Do Estado De Sergipe S.A' },
    { value: '144', label: '144 - Bexs Banco De Cambio S.A.' },
    { value: '126', label: '126 - Br Partners Bi' },
    { value: '301', label: '301 - Bpp Instituição De Pagamentos S.A' },
    { value: '173', label: '173 - Brl Trust Dtvm Sa' },
    { value: '119', label: '119 - Banco Western Union' },
    { value: '254', label: '254 - Parana Banco S.A' },
    { value: '268', label: '268 - Barigui Ch' },
    { value: '107', label: '107 - Banco Bocom Bbm S.A' },
    { value: '412', label: '412 - Banco Capital S.A' },
    { value: '124', label: '124 - Banco Woori Bank Do Brasil S.A' },
    { value: '149', label: '149 - Facta S.A. Cfi' },
    { value: '197', label: '197 - Stone Pagamentos S.A' },
    { value: '142', label: '142 - Broker Brasil Cc Ltda' },
    { value: '184', label: '184 - Banco Itaú Bba S.A' },
    { value: '634', label: '634 - Banco Triangulo S.A (Banco Triângulo)' },
    { value: '545', label: '545 - Senso Ctvm S.A' },
    { value: '132', label: '132 - Icbc Do Brasil Bm S.A' },
    { value: '298', label: '298 - Vips Cc Ltda' },
    { value: '129', label: '129 - Ubs Brasil Bi S.A' },
    { value: '128', label: '128 - Ms Bank S.A Banco De Câmbio' },
  ];  

  const add = () => {
    setNewCliente({
      id: 0,
      nome_fantasia: '',
      razao_social: '',
      cpf_cnpj: '',
      inscricao_estadual: '',
      cep: '',
      endereco: '',
      numero: '',
      bairro: '',
      cidade: '',
      uf: '',
      celular: '',
      email: '',
      tipo_pessoa: 'F',
      tipo_cliente: 0,
      tipo_transportador: 0,
      tipo_fornecedor: 0
    });
    setIsEditing(false);
    setShow(true);
  };

  const edit = async (id: number) => {
    try {
      const cliente = await getClienteById(id);
      setNewCliente(cliente);
      setIsEditing(true);
      setShow(true);
    } catch (error) {
      console.error('Erro ao buscar cliente:', error);
    }
  };

  const notify = () => toast.info(alerta);

  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);

  const fetchClientes = async (search = '') => {
    try {
      const clientes = await getClientes(search);
      setClientes(clientes);
    } catch (error) {
      console.error('Erro ao buscar lista de clientes:', error);
    }
  };

  useEffect(() => {
    fetchClientes();
  }, []);

  const handleBanco = (selectedOption: { value: string; label: string } | null) => {
    setNewCliente({ ...newCliente, ['banco']: selectedOption?.value });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    
    const { name, value } = event.target;
    let mascara = false;

    if(name === 'cpf_cnpj_titular') {

      mascara = true;
    
      let cleanedValue = value.replace(/\D/g, '');
  
      let maskedValue;

      if (Number(value.length) < 15) {
        
          maskedValue = cleanedValue
              .replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d{1,2})/, '$1-$2')
              .replace(/(-\d{2})\d+?$/, '$1');
      } else {
        
          maskedValue = cleanedValue
              .replace(/(\d{2})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d)/, '$1/$2')
              .replace(/(\d{4})(\d{1,2})/, '$1-$2')
              .replace(/(-\d{2})\d+?$/, '$1');
      }
  
      setNewCliente({ ...newCliente, [name]: maskedValue });     
    }

    if (name === 'cpf_cnpj') {

        mascara = true;
    
        let cleanedValue = value.replace(/\D/g, '');
    
        let maskedValue;
 
        if (newCliente.tipo_pessoa === 'F') {
          
            maskedValue = cleanedValue
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1');
        } else {
          
            maskedValue = cleanedValue
                .replace(/(\d{2})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1/$2')
                .replace(/(\d{4})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1');
        }
    
        setNewCliente({ ...newCliente, [name]: maskedValue });
    }  
    
    if (name === 'cep') {
      
        mascara = true;

        let cleanedValue = value.replace(/\D/g, ''); 
        
        let maskedValue = cleanedValue
        .replace(/(\d{5})(\d)/, '$1-$2');
        
        setNewCliente({ ...newCliente, [name]: maskedValue });
        
        if (maskedValue.length === 9) {
          fetchAddress(maskedValue);
        }
      }

    if (name === 'telefone_fixo') {
      
        mascara = true;

        let cleanedValue = value.replace(/\D/g, '');
        
        let maskedValue = cleanedValue
        .replace(/\D/g, '') 
        .replace(/(\d{2})(\d)/, '($1) $2') 
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');

        console.log(name, value, maskedValue);

        setNewCliente({ ...newCliente, [name]: maskedValue });
    }

    if (name === 'celular') {
        
        mascara = true;
        
        let cleanedValue = value.replace(/\D/g, '');
        
        let maskedValue = cleanedValue
        .replace(/\D/g, '') 
        .replace(/(\d{2})(\d)/, '($1) $2') 
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
        
        setNewCliente({ ...newCliente, [name]: maskedValue });
    }

    if(mascara === false){

        setNewCliente({ ...newCliente, [name]: value });
    }  
  };

  const normalizeData = (cliente: Cliente): Cliente => {
    return {
      ...cliente,
      complemento: cliente.complemento || '',
      telefone_fixo: cliente.telefone_fixo || '',
      dados_bancarios: cliente.dados_bancarios || '',
      banco: cliente.banco || '',
      agencia: cliente.agencia || '',
      conta: cliente.conta || '',
      titular_conta: cliente.titular_conta || '',
      cpf_cnpj_titular: cliente.cpf_cnpj_titular || '',
      antt: cliente.antt || '',
    };
  };

  const handleSubmit = async () => {
    setAlerta('');

    if (!newCliente.nome_fantasia || !newCliente.cpf_cnpj) {
      setAlerta('Os campos Nome Fantasia e CPF/CNPJ são obrigatórios.');
      return;
    }

    try {

      const clienteData = normalizeData(newCliente);
      let result: any;

      if (isEditing) {

        result = await updateCliente(clienteData);
      } else {
        
        result = await createCliente(clienteData);
      }
  
      if (result.status === 'error') {
        setAlerta(result.messages[0]);
      } else {
        fetchClientes('');
        handleClose();
        setAlerta(isEditing ? 'Cliente atualizado com sucesso.' : 'Cliente cadastrado com sucesso.');
      }
    } catch (error) {
      setAlerta('Não foi possível realizar esta operação.');
    }
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === '' || search.trim().length >= 3) {
      fetchClientes(search.trim());
    } else {
      setAlerta('O termo de pesquisa deve ter pelo menos 3 caracteres.');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === '') {
      fetchClientes('');
    }
  };

  const handleCep = (e: any) => {
    let cleanedValue = e.target.value.replace(/\D/g, ''); 
    let maskedValue = cleanedValue.replace(/(\d{5})(\d)/, '$1-$2');
    setNewCliente({ ...newCliente, ['cep']: maskedValue });
  }

  const [inscricaoPlaceholder, setInscricaoPlaceholder] = useState("Selecione o Estado");
  const [disabledInscricao, setDisabledInscricao] = useState(true);
  const [filteredMunicipios, setFilteredMunicipios] = useState<any[]>([]);

  const fetchAddress = async (cep: string) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (!data.erro) {
        setNewCliente((prevCliente) => ({
          ...prevCliente,
          endereco: data.logradouro,
          complemento: data.complemento,
          bairro: data.bairro,
          cidade: data.localidade,
        }));
      } else {
        setAlerta('CEP não encontrado.');
      }
    } catch (error) {
      setAlerta('Erro ao buscar endereço. Tente novamente.');
    }
  };

  const isTransportadorSelected = newCliente.tipo_transportador;

  const handleCliente = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if(name === 'tipo_pessoa'){

      newCliente.cpf_cnpj = '';

      if(value === 'J'){

        setRazaoSocialNome('Razão Social');
        setNomeFantasiaApelido('Nome Fantasia');
        setCpfCnpj('CNPJ');

      }else{

        setRazaoSocialNome('Nome');
        setNomeFantasiaApelido('Apelido');  
        setCpfCnpj('CPF');
      }
    }

    if (name === 'cep') {
      if (value.length === 9) {
        fetchAddress(value);
      }
    }
    
    if (name === 'uf') {
      newCliente.cidade = '';
      newCliente.cep = '';
      newCliente.inscricao_estadual = '';
      newCliente.endereco = '';
      newCliente.numero = '';
      newCliente.complemento = '';
      newCliente.bairro = '';
      
      setNewCliente(prevState => ({
        ...prevState,
        inscricao_estadual: '',
        [name]: value
      }));
      
      if (value === '') {
        setDisabledInscricao(true);
        setInscricaoPlaceholder("Selecione o Estado");
      } else {
        setDisabledInscricao(false);
        
        const placeholders: { [key: string]: { placeholder: string, maxlength: number } } = {
          'AC': { placeholder: "__/____.____/___-__", maxlength: 18 },
          'AL': { placeholder: "_________", maxlength: 9 },
          'AM': { placeholder: "___.___.___-_", maxlength: 12 },
          'AP': { placeholder: "_________", maxlength: 9 },
          'BA': { placeholder: "______-__", maxlength: 9 },
          'CE': { placeholder: "________-_", maxlength: 10 },
          'DF': { placeholder: "__.___.___/___-__", maxlength: 15 },
          'ES': { placeholder: "________-_", maxlength: 10 },
          'GO': { placeholder: "__.____.____-_", maxlength: 12 },
          'MA': { placeholder: "________-_", maxlength: 10 },
          'MG': { placeholder: "_______.__-__", maxlength: 13 },
          'MS': { placeholder: "________-_", maxlength: 10 },
          'MT': { placeholder: "__________-_", maxlength: 12 },
          'PA': { placeholder: "__.___.___-_", maxlength: 12 },
          'PB': { placeholder: "________-_", maxlength: 10 },
          'PE': { placeholder: "________-_", maxlength: 10 },
          'PI': { placeholder: "________-_", maxlength: 10 },
          'PR': { placeholder: "___._____-__", maxlength: 11 },
          'RJ': { placeholder: "__.___.__-_", maxlength: 10 },
          'RN': { placeholder: "__.___.___-_", maxlength: 12 },
          'RO': { placeholder: "_____________-_", maxlength: 15 },
          'RR': { placeholder: "________-_", maxlength: 10 },
          'RS': { placeholder: "___/_______", maxlength: 11 },
          'SC': { placeholder: "___.___.___", maxlength: 11 },
          'SE': { placeholder: "________-_", maxlength: 10 },
          'SP': { placeholder: "___.___.___.___", maxlength: 15 },
          'TO': { placeholder: "__________-_", maxlength: 12 }
        };
        
        const { placeholder, maxlength } = placeholders[value] || { placeholder: "Selecione o Estado", maxlength: 100 };
        
        setInscricaoPlaceholder(placeholder);
        setUfComboSel(value);
        setNewCliente(prevState => ({
          ...prevState,
          inscricao_estadual: '',
          maxlength
        }));
      }
    } else {
      if (name == 'cep' && value.length === 9) {
        applyMask(value, ufComboSel);
      }
      
      const maskedValue = name === 'inscricao_estadual' ? applyMask(value, ufComboSel) : value;
      
      setNewCliente(prevState => ({
        ...prevState,
        [name]: maskedValue
      }));

      if (name === 'nascimento') {
        let cleanedValue = value.replace(/\D/g, '');
        
        let maskedValue = cleanedValue
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d{1,4})/, '$1/$2');
        
        setNewCliente(prevState => ({
          ...prevState,
          [name]: maskedValue
        }));
      } 
    }
  };

  const applyMask = (value: string, uf: string) => {
    let cleanedValue = value.replace(/\D/g, '');
    switch (uf) {
      case 'AC': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1,3})?(\d{1,2})?/, (_, p1, p2, p3, p4, p5) => 
        [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `/${p4}`, p5 && `-${p5}`].filter(Boolean).join(''));
      case 'AL': return cleanedValue.replace(/(\d{1,9})/, '$1');
      case 'AM': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/, (_, p1, p2, p3, p4) => 
        [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join(''));
      case 'AP': return cleanedValue.replace(/(\d{1,9})/, '$1');
      case 'BA': return cleanedValue.replace(/(\d{1,6})(\d{1,2})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'CE': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'DF': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d{1,2})?/, (_, p1, p2, p3, p4, p5) => 
        [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `/${p4}`, p5 && `-${p5}`].filter(Boolean).join(''));
      case 'ES': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'GO': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/, (_, p1, p2, p3, p4) => 
        [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join(''));
      case 'MA': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'MG': return cleanedValue.replace(/(\d{1,7})?(\d{1,2})?(\d{1,2})?/, (_, p1, p2, p3) => 
        [p1, p2 && `.${p2}`, p3 && `-${p3}`].filter(Boolean).join(''));
      case 'MS': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'MT': return cleanedValue.replace(/(\d{1,10})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'PA': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/, (_, p1, p2, p3, p4) => 
        [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join(''));
      case 'PB': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'PE': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'PI': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'PR': return cleanedValue.replace(/(\d{1,3})(\d{1,5})?(\d{1,2})?/, (_, p1, p2, p3) => 
        [p1, p2 && `.${p2}`, p3 && `-${p3}`].filter(Boolean).join(''));
      case 'RJ': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,2})?(\d{1})?/, (_, p1, p2, p3, p4) => 
        [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join(''));
      case 'RN': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/, (_, p1, p2, p3, p4) => 
        [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join(''));
      case 'RO': return cleanedValue.replace(/(\d{1,13})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'RR': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'RS': return cleanedValue.replace(/(\d{1,3})(\d{1,7})?/, (_, p1, p2) => 
        [p1, p2 && `/${p2}`].filter(Boolean).join(''));
      case 'SC': return cleanedValue.replace(/(\d{1,3})(\d{1,3})?(\d{1,3})?/, (_, p1, p2, p3) => 
        [p1, p2 && `.${p2}`, p3 && `.${p3}`].filter(Boolean).join(''));
      case 'SE': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      case 'SP': return cleanedValue.replace(/(\d{1,3})(\d{1,3})?(\d{1,3})?(\d{1,3})?/, (_, p1, p2, p3, p4) => 
        [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `.${p4}`].filter(Boolean).join(''));
      case 'TO': return cleanedValue.replace(/(\d{1,10})(\d{1})?/, (_, p1, p2) => 
        [p1, p2 && `-${p2}`].filter(Boolean).join(''));
      default: return value;
    }
  };

  useEffect(() => {
    const fetchMunicipios = async () => {
      const response = await fetch('/municipios.json');
      const data: Municipio[] = await response.json();
      setMunicipios(data);
    };
    
    fetchMunicipios();
  }, []);

  useEffect(() => {
    if (newCliente.uf) {

      setFilteredMunicipios(municipios.filter(municipio => municipio.estado === newCliente.uf));
    } else {
      setFilteredMunicipios([]);
    }
  }, [newCliente.uf, municipios]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setNewCliente(prevState => ({
      ...prevState,
      [name]: checked ? 1 : 0,
      antt: name === 'tipo_transportador' && !checked ? '' : prevState.antt,
    }));
  };
  
  const style = document.createElement("style");
  style.innerHTML = `
      .modal-dialog{
        min-width: 90%;
      }
    `;
  document.head.appendChild(style);

  return (
    <>
      <HeaderComponent />
      <div className='page-content'>
        <MenuComponent />
        <div className='content-wrapper'>
          <div className='content-inner'>
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a onClick={() => navigate('/')} className="breadcrumb-item">
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">Clientes</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='content'>
              <div className='card'>
                <div className="card-header">
                  <h5 className="card-title d-flex float-left">
                    <Button className='bt-1-ico' variant="indigo" onClick={add}><IoAddCircleOutline className='m-0-auto fs-2em' /></Button>
                    <span className='fs-1-5em pl-2'>Clientes</span>
                  </h5>
                  <div className='float-right'>
                    <form onSubmit={handleSearchSubmit}>
                      <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Pesquisar por nome fantasia ou CPF/CNPJ..."
                          value={search}
                          onChange={handleSearchChange}
                        />
                        <div className="form-control-feedback form-control-feedback-lg pt-0">
                          <button type="submit" className="btn btn-link p-0">
                            <IoSearch className='fs-1-7em' />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='card-body'>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Nome Fantasia</th>
                          <th>Razão Social</th>
                          <th>CPF/CNPJ</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clientes.map(cliente => (
                          <tr key={cliente.id} onClick={() => edit(cliente.id)} className='cursor-pointer'>
                            <td>{cliente.id}</td>
                            <td>{cliente.nome_fantasia}</td>
                            <td>{cliente.razao_social}</td>
                            <td>{cliente.cpf_cnpj}</td>
                            <td>{cliente.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
  <Modal.Header>
    <Modal.Title>{isEditing ? 'Editar Cliente' : 'Novo Cliente / Fornecedor / Transportador'}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Row>

    <Col lg={12}>
      <div className="form-group">
        <label>Tipo:</label>
        <div className='d-flex'>
          <div>
          <input 
  type="checkbox" 
  className="w-20 h-20 float-left mr-1" 
  name="tipo_cliente" 
  checked={newCliente.tipo_cliente === 1} 
  onChange={handleCheckboxChange} 
/>
            <label className="form-check-label">Cliente</label>
          </div>
          <div>
          <input 
            type="checkbox" 
            className="w-20 h-20 float-left mr-1 ml-3" 
            name="tipo_fornecedor" 
            checked={newCliente.tipo_fornecedor === 1} 
            onChange={handleCheckboxChange} 
          />
            <label className="form-check-label">Fornecedor</label>
          </div>
          <div>
          <input 
            type="checkbox" 
            className="w-20 h-20 float-left mr-1 ml-3" 
            name="tipo_transportador" 
            checked={newCliente.tipo_transportador === 1} 
            onChange={handleCheckboxChange} 
          />
            <label className="form-check-label">Transportador</label>
          </div>
        </div>
      </div>
    </Col>


    <Col lg={2}>
        <div className="form-group">
          <label>Tipo: </label>
          <select className='form-control' name="tipo_pessoa" value={newCliente.tipo_pessoa} onChange={handleCliente}>
            <option value="F">Pessoa Física</option>
            <option value="J">Pessoa Jurídica</option>
          </select>
        </div>
      </Col>

      <Col lg={5}>
        <div className="form-group">
          <label>{razaoSocialNome}: </label>
          <input type="text" className="form-control" name="razao_social" value={newCliente.razao_social} onChange={handleInputChange} />
        </div>
      </Col>

    <Col lg={5}>
        <div className="form-group">
          <label>{nomeFantasiaApelido}: </label>
          <input type="text" className="form-control" name="nome_fantasia" value={newCliente.nome_fantasia} onChange={handleInputChange} />
        </div>
      </Col>

      <Col lg={3}>
        <div className="form-group">
          <label>{cpfCnpj}: </label>
          <input type="text" className="form-control" name="cpf_cnpj" value={newCliente.cpf_cnpj} onChange={handleInputChange} />
        </div>
      </Col>

      <Col lg={3}>
        <div className="form-group">
          <label>Telefone Fixo: </label>
          <input type="text" className="form-control" name="telefone_fixo" value={newCliente.telefone_fixo || ''} onChange={handleInputChange} />
        </div>
      </Col>
      <Col lg={3}>
        <div className="form-group">
          <label>Celular: </label>
          <input type="text" className="form-control" name="celular" value={newCliente.celular} onChange={handleInputChange} />
        </div>
      </Col>
      <Col lg={3}>
        <div className="form-group">
          <label>Email: </label>
          <input type="email" className="form-control" name="email" value={newCliente.email} onChange={handleInputChange} />
        </div>
      </Col>

      <Col lg={3}>
        <div className="form-group">
          <label>Banco: </label>
          <Select
            options={bancoOptions}
            onChange={handleBanco}
            isClearable
            placeholder="Selecione um banco"
          />
        </div>
      </Col>
      <Col lg={2}>
        <div className="form-group">
          <label>Agência: </label>
          <input type="text" className="form-control" name="agencia" value={newCliente.agencia || ''} onChange={handleInputChange} />
        </div>
      </Col>
      <Col lg={2}>
        <div className="form-group">
          <label>Conta: </label>
          <input type="text" className="form-control" name="conta" value={newCliente.conta || ''} onChange={handleInputChange} />
        </div>
      </Col>
      <Col lg={3}>
        <div className="form-group">
          <label>Titular da Conta: </label>
          <input type="text" className="form-control" name="titular_conta" value={newCliente.titular_conta || ''} onChange={handleInputChange} />
        </div>
      </Col>
      <Col lg={2}>
        <div className="form-group">
          <label>CPF/CNPJ do Titular: </label>
          <input type="text" className="form-control" name="cpf_cnpj_titular" value={newCliente.cpf_cnpj_titular || ''} onChange={handleInputChange} />
        </div>
      </Col>

      <Col lg={3}>
        <div className="form-group">
          <label>ANTT: </label>
          <input 
          type="text" 
          className="form-control" 
          name="antt" 
          value={newCliente.antt || ''} 
          onChange={handleInputChange} 
          disabled={!isTransportadorSelected} 
        />        </div>
      </Col>

      <Col lg={2}>
        <div className="form-group">
          <label>Estado: </label>
          <select className='form-control' name="uf" value={newCliente.uf} onChange={handleCliente}>
            <option value="">-- selecione --</option>
            <option value="AC">Acre</option>
            <option value="AL">Alagoas</option>
            <option value="AP">Amapá</option>
            <option value="AM">Amazonas</option>
            <option value="BA">Bahia</option>
            <option value="CE">Ceará</option>
            <option value="DF">Distrito Federal</option>
            <option value="ES">Espírito Santo</option>
            <option value="GO">Goiás</option>
            <option value="MA">Maranhão</option>
            <option value="MT">Mato Grosso</option>
            <option value="MS">Mato Grosso do Sul</option>
            <option value="MG">Minas Gerais</option>
            <option value="PA">Pará</option>
            <option value="PB">Paraíba</option>
            <option value="PR">Paraná</option>
            <option value="PE">Pernambuco</option>
            <option value="PI">Piauí</option>
            <option value="RJ">Rio de Janeiro</option>
            <option value="RN">Rio Grande do Norte</option>
            <option value="RS">Rio Grande do Sul</option>
            <option value="RO">Rondônia</option>
            <option value="RR">Roraima</option>
            <option value="SC">Santa Catarina</option>
            <option value="SP">São Paulo</option>
            <option value="SE">Sergipe</option>
            <option value="TO">Tocantins</option>
          </select>
        </div>
      </Col>
      <Col lg={2}>
        <div className="form-group">
          <label>CEP: </label>
          <input type="text" className="form-control" value={newCliente.cep} placeholder="_____-___" name="cep" onKeyUp={handleCep} onChange={handleCliente} />
        </div>            
      </Col>
      <Col lg={3}>
        <div className="form-group">
          <label>Cidade: </label>
          <select className="form-control" value={newCliente.cidade} name="cidade" onChange={handleCliente} disabled={filteredMunicipios.length === 0} >
            <option value="">Selecione a Cidade</option>
            {filteredMunicipios.map(municipio => (
              <option key={municipio.cod_municipio} value={municipio.nome_municipio}>
                {municipio.nome_municipio}
              </option>
            ))}
          </select>
        </div>                         
      </Col>    
      <Col lg={2}>
        <div className="form-group">
          <label>Inscrição Estadual:</label>
          <input
            type="text"
            className="form-control"
            value={newCliente.inscricao_estadual}
            onChange={handleCliente}
            placeholder={inscricaoPlaceholder}
            name="inscricao_estadual"
            disabled={disabledInscricao}
            maxLength={newCliente.maxlength}
          />
        </div>
      </Col>
      <Col lg={5}>
        <div className="form-group">
          <label>Endereço: </label>
          <input type="text" className="form-control" value={newCliente.endereco} placeholder='Endereço' name="endereco" onChange={handleCliente} />
        </div>            
      </Col>
      <Col lg={2}>
        <div className="form-group">
          <label>Número: </label>
          <input type="text" className="form-control" value={newCliente.numero} name="numero" onChange={handleCliente} />
        </div>            
      </Col>
      <Col lg={2}>
        <div className="form-group">
          <label>Complemento: </label>
          <input type="text" className="form-control" value={newCliente.complemento} name="complemento" placeholder='Complemento' onChange={handleCliente} />
        </div>            
      </Col>
      <Col lg={3}>
        <div className="form-group">
          <label>Bairro: </label>
          <input type="text" className="form-control" value={newCliente.bairro} name="bairro" placeholder='Bairro' onChange={handleCliente} />
        </div>                         
      </Col>
    </Row>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Fechar
    </Button>
    <Button variant="primary" onClick={handleSubmit}>
      {isEditing ? 'Atualizar' : 'Salvar'}
    </Button>
  </Modal.Footer>
</Modal>


      <ToastContainer 
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
}

export default ClientesPage;