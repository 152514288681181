import Cookies from 'js-cookie';

const API_URL = 'https://bf-api.solutioweb.com.br/maquinas';

export const getMaquinaById = async (id: number) => {
    try {
        const response = await fetch(`${API_URL}/${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching inscricao estadual:', error);
        throw error;
    }
};

export const getMaquinas = async (id:any, search = '') => {
    try {
        const url = search ? `${API_URL}/lista/${id}?search=${encodeURIComponent(search)}` : `${API_URL}/lista/${id}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching inscricoes estaduais:', error);
        throw error;
    }
};

export const getMaquinasUsuario = async (idUsuario:number) => {
    try {
        const url = `${API_URL}/permissoes/usuario/${idUsuario}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching inscricoes estaduais:', error);
        throw error;
    }
};


export const createMaquina = async (maquina:any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();

        formData.append('idFazenda', maquina.idFazenda.toString());
        formData.append('descricao', maquina.descricao);
        formData.append('marca', maquina.marca);
        formData.append('modelo', maquina.modelo);
        formData.append('ano_fabricacao', maquina.ano_fabricacao);
        formData.append('ano_aquisicao', maquina.ano_aquisicao);
        formData.append('placa', maquina.placa);
        formData.append('valor_aquisicao', maquina.valor_aquisicao);
        formData.append('valor_atual', maquina.valor_atual);
        formData.append('vida_util', maquina.vida_util);
        formData.append('depreciacao_anual', maquina.depreciacao_anual);
        formData.append('valor_ultimo_exercicio', maquina.valor_ultimo_exercicio);
        formData.append('tipo', maquina.tipo);

        formData.append('ano1', maquina.ano1);
        formData.append('valor1', maquina.valor1);
        formData.append('ano2', maquina.ano2);
        formData.append('valor2', maquina.valor2);
        formData.append('ano3', maquina.ano3);
        formData.append('valor3', maquina.valor3);
        formData.append('ano4', maquina.ano4);
        formData.append('valor4', maquina.valor4);
        formData.append('ano5', maquina.ano5);
        formData.append('valor5', maquina.valor5);
        formData.append('ano6', maquina.ano6);
        formData.append('valor6', maquina.valor6);
        formData.append('ano7', maquina.ano7);
        formData.append('valor7', maquina.valor7);
        formData.append('ano8', maquina.ano8);
        formData.append('valor8', maquina.valor8);
        formData.append('ano9', maquina.ano9);
        formData.append('valor9', maquina.valor9);
        formData.append('ano10', maquina.ano10);
        formData.append('valor10', maquina.valor10);

        const response = await fetch(API_URL, {
            method: 'POST',
            body: formData
        });

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Falha ao cadastrar a inscricao estadual:', error);
        throw error;
    }
};

export const deleteMaquina = async (id:any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('id', id.toString());

        const response = await fetch(API_URL+'/delete', {
            method: 'POST',
            body: formData
        });

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Falha ao cadastrar a inscricao estadual:', error);
        throw error;
    }
};

export const permissao = async (idUsuario:any, idMaquina:any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('idUsuario', idUsuario);
        formData.append('idMaquina', idMaquina);

        const response = await fetch(API_URL+'/permissoes', {
            method: 'POST',
            body: formData
        });
 
        if (!response.ok) {
            return { 'error': 'Houve uma falha. Tente mais tarde.' };
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error creating inscricao estadual:', error);
        throw error;
    }
};

export const updateMaquina = async (maquina: any) => {
    try {
        const formData = new FormData();

        formData.append('idFazenda', maquina.idFazenda.toString());
        formData.append('descricao', maquina.descricao);
        formData.append('marca', maquina.marca);
        formData.append('modelo', maquina.modelo);
        formData.append('ano_fabricacao', maquina.ano_fabricacao);
        formData.append('ano_aquisicao', maquina.ano_aquisicao);
        formData.append('placa', maquina.placa);
        formData.append('valor_aquisicao', maquina.valor_aquisicao);
        formData.append('valor_atual', maquina.valor_atual);
        formData.append('vida_util', maquina.vida_util);
        formData.append('depreciacao_anual', maquina.depreciacao_anual);
        formData.append('valor_ultimo_exercicio', maquina.valor_ultimo_exercicio);
        formData.append('tipo', maquina.tipo);

        formData.append('ano1', maquina.ano1);
        formData.append('valor1', maquina.valor1);
        formData.append('ano2', maquina.ano2);
        formData.append('valor2', maquina.valor2);
        formData.append('ano3', maquina.ano3);
        formData.append('valor3', maquina.valor3);
        formData.append('ano4', maquina.ano4);
        formData.append('valor4', maquina.valor4);
        formData.append('ano5', maquina.ano5);
        formData.append('valor5', maquina.valor5);
        formData.append('ano6', maquina.ano6);
        formData.append('valor6', maquina.valor6);
        formData.append('ano7', maquina.ano7);
        formData.append('valor7', maquina.valor7);
        formData.append('ano8', maquina.ano8);
        formData.append('valor8', maquina.valor8);
        formData.append('ano9', maquina.ano9);
        formData.append('valor9', maquina.valor9);
        formData.append('ano10', maquina.ano10);
        formData.append('valor10', maquina.valor10);

        const token = Cookies.get("TokenBrasao");

        const response = await fetch(`${API_URL}/${maquina.id}`, {
            method: 'PUT',
            body: formData
        });

        if (!response.ok) {
            return { 'error': 'Houve uma falha. Tente mais tarde.' };
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error updating máquina:', error);
        throw error;
    }
};