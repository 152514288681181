import Cookies from 'js-cookie';

const API_URL = 'https://bf-api.solutioweb.com.br/talhoes';

export const getTalhaoById = async (id: number) => {
    try {
        const response = await fetch(`${API_URL}/${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching inscricao estadual:', error);
        throw error;
    }
};

export const getTalhoes = async (id:any, search = '') => {
    try {
        const url = search ? `${API_URL}/lista/${id}?search=${encodeURIComponent(search)}` : `${API_URL}/lista/${id}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching inscricoes estaduais:', error);
        throw error;
    }
};

export const getTalhoesUsuario = async (idUsuario:number) => {
    try {
        const url = `${API_URL}/permissoes/usuario/${idUsuario}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching inscricoes estaduais:', error);
        throw error;
    }
};

export const createTalhao = async (inscricao:any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('idFazenda', inscricao.idFazenda.toString());
        formData.append('nome', inscricao.nome);
        formData.append('area', inscricao.area);

        const response = await fetch(API_URL, {
            method: 'POST',
            body: formData
        });

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Falha ao cadastrar a inscricao estadual:', error);
        throw error;
    }
};

export const updateTalhao = async (talhao: any) => {
    try {
        const formData = new FormData();

        formData.append('idFazenda', talhao.idFazenda.toString());
        formData.append('nome', talhao.nome);
        formData.append('area', talhao.area);

        const token = Cookies.get("TokenBrasao");

        const response = await fetch(`${API_URL}/${talhao.id}`, {
            method: 'PUT',
            body: formData
        });

        if (!response.ok) {
            return { 'error': 'Houve uma falha. Tente mais tarde.' };
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error updating inscricao estadual:', error);
        throw error;
    }
};

export const deleteTalhao = async (id:any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('id', id.toString());

        const response = await fetch(API_URL+'/delete', {
            method: 'POST',
            body: formData
        });

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Falha ao cadastrar a inscricao estadual:', error);
        throw error;
    }
};