import Cookies from 'js-cookie';

const API_URL = 'https://bf-api.solutioweb.com.br/contas-bancarias';

export const getContaById = async (id: number) => {
    try {
        const response = await fetch(`${API_URL}/${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching conta bancaria:', error);
        throw error;
    }
};

export const getContas = async (search = '') => {
    try {
        const url = search ? `${API_URL}/lista?search=${encodeURIComponent(search)}` : `${API_URL}/lista`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching contas bancarias:', error);
        throw error;
    }
};

export const createConta = async (conta: any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();

        formData.append('descricao', conta.descricao);
        formData.append('codigo_banco', conta.codigo_banco);
        formData.append('agencia', conta.agencia);
        formData.append('conta', conta.conta);
        formData.append('saldo_inicial', conta.saldo_inicial);
        formData.append('saldo_atual', conta.saldo_atual);
        formData.append('tipo_conta', conta.tipo_conta);
        formData.append('nome_titular', conta.nome_titular);
        formData.append('cpf_titular', conta.cpf_titular);

        if (conta.nome_segundo_titular) {
            formData.append('nome_segundo_titular', conta.nome_segundo_titular);
        }
        if (conta.cpf_segundo_titular) {
            formData.append('cpf_segundo_titular', conta.cpf_segundo_titular);
        }

        formData.append('fazendas', JSON.stringify(conta.fazendas));

        const response = await fetch(API_URL, {
            method: 'POST',
            body: formData
        });

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Falha ao cadastrar a conta bancaria:', error);
        throw error;
    }
};

export const updateConta = async (conta: any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('descricao', conta.descricao);
        formData.append('codigo_banco', conta.codigo_banco);
        formData.append('agencia', conta.agencia);
        formData.append('conta', conta.conta);
        formData.append('saldo_inicial', conta.saldo_inicial);
        formData.append('saldo_atual', conta.saldo_atual);
        formData.append('tipo_conta', conta.tipo_conta);
        formData.append('nome_titular', conta.nome_titular);
        formData.append('cpf_titular', conta.cpf_titular);

        if (conta.nome_segundo_titular) {
            formData.append('nome_segundo_titular', conta.nome_segundo_titular);
        }
        if (conta.cpf_segundo_titular) {
            formData.append('cpf_segundo_titular', conta.cpf_segundo_titular);
        }

        formData.append('fazendas', JSON.stringify(conta.fazendas));

        const response = await fetch(`${API_URL}/${conta.id}`, {
            method: 'PUT',
            body: formData
        });

        if (!response.ok) {
            return { 'error': 'Houve uma falha. Tente mais tarde.' };
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error updating conta bancaria:', error);
        throw error;
    }
};

export const deleteConta = async (id: any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('id', id.toString());

        const response = await fetch(`${API_URL}/delete`, {
            method: 'POST',
            body: formData
        });

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Falha ao deletar a conta bancaria:', error);
        throw error;
    }
};