import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logomarca from '../assets/images/logo-belga-azul.png';
import { auth } from '../services/userService';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        const token = Cookies.get("TokenBrasao");
        if (token) {
            navigate("/", { replace: true });
        }
    }, [navigate]);

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [alerta, setAlerta] = useState('');
    const [disabled, setDisabled] = useState(false);

    const handleEmailOrCPFChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        if (numericValue.length === 11) {
            const maskedCPF = numericValue
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
            setEmail(maskedCPF);
        } else {
            setEmail(inputValue);
        }
    };

    const login = async (e: React.FormEvent) => {
        e.preventDefault();
        
        setAlerta('');

        try {
            setDisabled(true);
            const response = await auth(email, senha);
            if (response.status === 'success') {
                const token = response.data['id'];
                const nome = response.data['nome'];
                const tipo = response.data['tipo'];
                
                Cookies.set('TokenBrasao', token);
                Cookies.set('NomeUserBrasao', nome);
                Cookies.set('TipoUserBrasao', tipo);
                navigate('/');
            } else {
                setAlerta(response.message);
                setDisabled(false);
            }
        } catch (error: any) {
            setAlerta(error.message || 'Erro de autenticação');
        }
    };

    useEffect(() => {
        if (alerta) {
            toast.info(alerta, {
                onClose: () => setAlerta('')
            });
        }
    }, [alerta]);

    return (
        <>
            <div className="page-content">
                <div className="content-wrapper">
                    <div className="content-inner">
                        <div className="content d-flex justify-content-center align-items-center">
                            <form className="login-form mt-5" onSubmit={login}>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="text-center mb-3">
                                            <img src={logomarca} className="mb-3 mt-1 h-50px" alt="Belga Agrotecnologia" />
                                            <h5 className="mb-0">Faça seu login</h5>
                                            <span className="d-block text-muted">Digite suas credenciais de acesso aqui</span>
                                        </div>
                                        <div className="form-group form-group-feedback form-group-feedback-left">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="E-mail ou CPF"
                                                value={email}
                                                onChange={handleEmailOrCPFChange}
                                            />
                                            <div className="form-control-feedback">
                                                <i className="icon-user text-muted"></i>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-feedback form-group-feedback-left">
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Senha"
                                                value={senha}
                                                onChange={(e) => setSenha(e.target.value)}
                                            />
                                            <div className="form-control-feedback">
                                                <i className="icon-lock2 text-muted"></i>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" disabled={disabled} className="btn btn-primary btn-block">Fazer login</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="btn-to-top">
                        <button type="button" className="btn btn-dark btn-icon rounded-pill">
                            <i className="icon-arrow-up8"></i>
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer 
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
            />
        </>
    );
}

export default LoginPage;