import React from 'react';
import logomarca from '../assets/images/logo-belga-azul.png';
import { useNavigate } from 'react-router-dom';

const HeaderComponent: React.FC = () => {

  const navigate = useNavigate();

  const deslogar = () => {

    navigate("/logout");
  }

  return (
    <div className="navbar navbar-expand-lg navbar-dark bg-white navbar-static">
      <div className="d-flex flex-1 d-lg-none">
        <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
          <i className="icon-paragraph-justify3"></i>
        </button>
      </div>
      <div className="navbar-brand text-center text-lg-left p-1">
        <a href="/" className="d-inline-block">
          <img src={logomarca} className="d-none d-sm-block h-40px my-1" alt="Belga Agrotecnologia" />
          <img src={logomarca} className="d-sm-none h-40px my-1" alt="Belga Agrotecnologia" />
        </a>
      </div>

      <div className="navbar-collapse collapse flex-lg-1 mx-lg-3 order-2 order-lg-1">
      </div>

      <div className="d-flex justify-content-end align-items-center flex-1 flex-lg-0 order-1 order-lg-2">
        <ul className="navbar-nav flex-row">
          <li className="nav-item">
            <a href="" className="navbar-nav-link navbar-nav-link-toggler" onClick={deslogar}>
              <i className="icon-switch2"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderComponent;