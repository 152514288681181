import Cookies from 'js-cookie';

const API_URL = 'https://bf-api.solutioweb.com.br/producao';

export const getProducaoById = async (id: number) => {
    try {
        const response = await fetch(`${API_URL}/${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching producao:', error);
        throw error;
    }
};

export const getProducoes = async (id: any, search = '') => {
    try {
        const url = search ? `${API_URL}/lista/${id}?search=${encodeURIComponent(search)}` : `${API_URL}/lista/${id}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching producoes:', error);
        throw error;
    }
};

export const createProducao = async (producao: any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();

        formData.append('idFazenda', producao.idFazenda);
        formData.append('produto', producao.produto);
        formData.append('subProduto', producao.subProduto);
        formData.append('armazenamento', producao.armazenamento);
        formData.append('unidade', producao.unidade);
        formData.append('dataPlantio', producao.dataPlantio);
        formData.append('cicloEstimado', producao.cicloEstimado);
        formData.append('dataColheitaEstimada', producao.dataColheitaEstimada);
        formData.append('dataColheita', producao.dataColheita);
        formData.append('valorUnitario', producao.valorUnitario);
        formData.append('idTalhao', producao.talhao);
        formData.append('variedadeProduto', producao.variedadeProduto);
        formData.append('valorTotal', producao.valorTotal);
        formData.append('safra', producao.safra);
        formData.append('produtividade', producao.produtividade);

        const response = await fetch(API_URL, {
            method: 'POST',
            body: formData
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Falha ao cadastrar a producao:', error);
        throw error;
    }
};

export const deleteProducao = async (id: any) => {
    try {
        const formData = new FormData();
        formData.append('id', id.toString());

        const response = await fetch(`${API_URL}/delete`, {
            method: 'POST',
            body: formData
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Falha ao deletar a producao:', error);
        throw error;
    }
};

export const updateProducao = async (producao: any) => {
    try {
        const formData = new FormData();

        formData.append('idFazenda', producao.idFazenda.toString());
        formData.append('produto', producao.produto);
        formData.append('subProduto', producao.subProduto);
        formData.append('armazenamento', producao.armazenamento);
        formData.append('unidade', producao.unidade);
        formData.append('dataPlantio', producao.dataPlantio);
        formData.append('cicloEstimado', producao.cicloEstimado.toString());
        formData.append('dataColheitaEstimada', producao.dataColheitaEstimada);
        formData.append('dataColheita', producao.dataColheita);
        formData.append('valorUnitario', producao.valorUnitario);
        formData.append('idTalhao', producao.talhao);
        formData.append('variedadeProduto', producao.variedadeProduto);
        formData.append('valorTotal', producao.valorTotal);
        formData.append('safra', producao.safra);
        formData.append('produtividade', producao.produtividade);

        const response = await fetch(`${API_URL}/${producao.id}`, {
            method: 'PUT',
            body: formData
        });

        if (!response.ok) {
            return { error: 'Houve uma falha. Tente mais tarde.' };
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error updating producao:', error);
        throw error;
    }
};