import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponent from '../components/MenuComponent';
import { IoMdHome } from "react-icons/io";
import { Loader } from "@googlemaps/js-api-loader";

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: -22.9035,
  lng: -43.2096
};

const AreasPage: React.FC = () => {
  const navigate = useNavigate();
  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyDh3cK5OT4re2RL4vui9AGJOkzirK93fKk",
      version: "weekly",
      libraries: ["drawing"],
    });

    loader.load().then(() => {
      const google = window.google;
      const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center,
        zoom: 13,
        mapTypeId: "satellite",
      });

      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        markerOptions: {
          draggable: true,
        },
        polygonOptions: {
          fillColor: '#2196F3',
          fillOpacity: 0.5,
          strokeWeight: 2,
          clickable: true,
          editable: true,
          zIndex: 1,
        },
      });

      drawingManager.setMap(map);
      setMap(map);
    });
  }, []);

  return (
    <>
      <HeaderComponent />
      <div className='page-content'>
        <MenuComponent />
        <div className='content-wrapper'>
          <div className='content-inner'>
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a onClick={() => navigate('/')} className="breadcrumb-item">
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">Areas</span>
                  </div>
                  <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more"></i></a>
                </div>
              </div>
            </div>
            <div className='content'>
              <div id="map" style={containerStyle}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AreasPage;