import Cookies from 'js-cookie';

const API_URL = 'https://bf-api.solutioweb.com.br/inscricoes-estaduais';

export const getInscricaoEstadualById = async (id: number) => {
    try {
        const response = await fetch(`${API_URL}/${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching inscricao estadual:', error);
        throw error;
    }
};

export const getInscricoesEstaduais = async (id:any, search = '') => {
    try {
        const url = search ? `${API_URL}/lista/${id}?search=${encodeURIComponent(search)}` : `${API_URL}/lista/${id}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching inscricoes estaduais:', error);
        throw error;
    }
};

export const getInscricoesEstaduaisUsuario = async (idUsuario:number) => {
    try {
        const url = `${API_URL}/permissoes/usuario/${idUsuario}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching inscricoes estaduais:', error);
        throw error;
    }
};


export const createInscricaoEstadual = async (inscricao:any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        
        formData.append('idFazenda', inscricao.idFazenda.toString());
        formData.append('inscricao_estadual', inscricao.inscricao_estadual);
        formData.append('cep', inscricao.cep);
        formData.append('cidade', inscricao.cidade);
        formData.append('bairro', inscricao.bairro);
        formData.append('complemento', inscricao.complemento);
        formData.append('endereco', inscricao.endereco);
        formData.append('numero', inscricao.numero);
        formData.append('uf', inscricao.uf);
        formData.append('matricula', inscricao.matricula);

        const response = await fetch(API_URL, {
            method: 'POST',
            body: formData
        });

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Falha ao cadastrar a inscricao estadual:', error);
        throw error;
    }
};

export const deleteInscricaoEstadual = async (id:any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('id', id.toString());

        const response = await fetch(API_URL+'/delete', {
            method: 'POST',
            body: formData
        });

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Falha ao cadastrar a inscricao estadual:', error);
        throw error;
    }
};

export const permissao = async (idUsuario:any, idInscricao:any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('idUsuario', idUsuario);
        formData.append('idInscricao', idInscricao);

        const response = await fetch(API_URL+'/permissoes', {
            method: 'POST',
            body: formData
        });
 
        if (!response.ok) {
            return { 'error': 'Houve uma falha. Tente mais tarde.' };
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error creating inscricao estadual:', error);
        throw error;
    }
};

export const updateInscricaoEstadual = async (inscricao: any) => {
    try {
        const formData = new FormData();
    
        formData.append('idFazenda', inscricao.idFazenda.toString());
        formData.append('inscricao_estadual', inscricao.inscricao_estadual);
        formData.append('cep', inscricao.cep);
        formData.append('cidade', inscricao.cidade);
        formData.append('bairro', inscricao.bairro);
        formData.append('complemento', inscricao.complemento);
        formData.append('endereco', inscricao.endereco);
        formData.append('numero', inscricao.numero);
        formData.append('uf', inscricao.uf);
        formData.append('matricula', inscricao.matricula);        

        const token = Cookies.get("TokenBrasao");

        const response = await fetch(`${API_URL}/${inscricao.id}`, {
            method: 'PUT',
            body: formData
        });

        if (!response.ok) {
            return { 'error': 'Houve uma falha. Tente mais tarde.' };
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error updating inscricao estadual:', error);
        throw error;
    }
};