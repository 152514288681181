import React from 'react';
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import UsuariosPage from "./pages/UsuariosPage";
import LogoutPage from "./pages/LogoutPage";
import FazendasPage from './pages/FazendasPage';
import VendasPage from './pages/VendasPage';
import AreasPage from './pages/AreasPage';
import InscricoesEstaduaisPage from './pages/InscricoesEstaduaisPage';
import GruposEconomicosPage from './pages/GruposEconomicosPage';
import PermissoesPage from './pages/PermissoesPage';
import AlterarsenhaPage from './pages/AlterarsenhaPage';
import ContasBancariasPage from './pages/ContasBancariasPage';
import FazendaDetailPage from './pages/FazendaDetailPage';
import ClientesPage from './pages/ClientesPage';
import Cookies from 'js-cookie';

import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";

import './assets/template/layout_1/LTR/material/full/assets/css/all.min.css';
import './assets/template/global_assets/css/icons/icomoon/styles.min.css';
import './assets/css/custom.scss';

function ProtectedRoute({ element, ...rest }: { element: JSX.Element, [key: string]: any }) {
  
  const token = Cookies.get("TokenBrasao");
  return token ? element : <Navigate to="/login" />;
}

function App() {
 
  let alturaMenu = (window.innerHeight - 150);

  const style = document.createElement("style");
  style.innerHTML = `
    .menuLateral{
      height: ${alturaMenu}px !important;
    }
  `;
  document.head.appendChild(style);

  return (
    <BrowserRouter>
      <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<ProtectedRoute element={<HomePage />} />} /> 
      <Route path="/usuarios" element={<ProtectedRoute element={<UsuariosPage />} />} /> 
      <Route path="/fazendas" element={<ProtectedRoute element={<FazendasPage />} />} /> 
      <Route path="/vendas" element={<ProtectedRoute element={<VendasPage />} />} /> 
      <Route path="/inscricoes-estaduais" element={<ProtectedRoute element={<InscricoesEstaduaisPage />} />} />  
      <Route path="/grupos-economicos" element={<ProtectedRoute element={<GruposEconomicosPage />} />} />  
      <Route path="/areas" element={<ProtectedRoute element={<AreasPage />} />} /> 
      <Route path="/permissoes" element={<ProtectedRoute element={<PermissoesPage />} />} /> 
      <Route path="/alterar-senha" element={<ProtectedRoute element={<AlterarsenhaPage />} />} />
      <Route path="/contas-bancarias" element={<ProtectedRoute element={<ContasBancariasPage />} />} />
      <Route path="/clientes" element={<ProtectedRoute element={<ClientesPage />} />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/fazenda/:id" element={<ProtectedRoute element={<FazendaDetailPage />} />} />
      <Route path="/*" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;