import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    
    Cookies.remove("TokenBrasao", { path: '' });

    navigate("/login", { replace: true });
  }, [navigate]);

  return null;
};

export default LogoutPage;