import React, { useState, useEffect, ChangeEvent } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponent from '../components/MenuComponent';
import InputDecimal2Component from '../components/Inputdecimal2Component';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Row, Col, Tab, Tabs, Form, Table } from 'react-bootstrap';
import { getFazendas, getFazendaById, createFazenda, updateFazenda } from '../services/fazendaService';
import { getInscricoesEstaduais, getInscricaoEstadualById, createInscricaoEstadual, deleteInscricaoEstadual, updateInscricaoEstadual } from '../services/inscricaoEstadualService';
import { getTalhoes, getTalhaoById, createTalhao, deleteTalhao, updateTalhao } from '../services/talhaoService';
import { getMaquinas, getMaquinaById, createMaquina, deleteMaquina, updateMaquina } from '../services/maquinaService';
import { getProducoes, getProducaoById, createProducao, updateProducao, deleteProducao } from '../services/producaoService';
import { IoMdHome } from "react-icons/io";
import { IoAddCircleOutline } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";
import { FaPlus, FaTrash, FaCheck } from "react-icons/fa";
import { Slide, ToastContainer, toast } from 'react-toastify';
import { number } from 'prop-types';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';

export interface Fazenda {
  id: number;
  nome: string;
  idUser: string;
  valor_hectare: string;
  data_cadastro: string;
  data_alteracao: string;
}

export interface Producao {

  id: number;
  produto: string;
  subProduto: string;
  armazenamento: string;
  unidade: string;
  dataPlantio: string;
  produtividade: number;
  valorUnitario: string;
  dataColheita: string;
  variedadeProduto: string;
  cicloEstimado: string;
  dataColheitaEstimada: string;
  valorTotal: string;
  safra: string;
  talhao: string;
}

export interface InscricaoEstadual {
  id: number;
  idFazenda: number;
  uf: string;
  cep: string;
  endereco: string;
  bairro: string;
  cidade: string;
  numero: string; 
  complemento: string;
  inscricao_estadual: string;
  matricula: string;
  fazendaNome?: string;
  data_cadastro: string;
  data_alteracao: string;
}

export interface Talhao {
  id: number;
  idFazenda: number;
  nome: string;
  area: string;
}

interface ProducaoState {
  id: number | null;
  produto: string;
  subProduto: string;
  armazenamento: string;
  unidade: string;
  dataPlantio: string;
  produtividade: number;
  valorUnitario: string;
  dataColheita: string;
  variedadeProduto: string;
  cicloEstimado: string;
  dataColheitaEstimada: string;
  valorTotal: string;
  safra: string;
  talhao: string;
  idFazenda: number;
}

interface MaquinaState {
  id: number | null;
  descricao: string;
  marca: string;
  modelo: string;
  tipo: string;
  ano_fabricacao: string;
  ano_aquisicao: string;
  placa: string;
  valor_aquisicao: string;
  valor_atual: string;
  vida_util: string;
  depreciacao_anual: string;
  valor_ultimo_exercicio: string;
  idFazenda: number;
  ano1: string;
  valor1: string;
  ano2: string;
  valor2: string;
  ano3: string;
  valor3: string;
  ano4: string;
  valor4: string;
  ano5: string;
  valor5: string;
  ano6: string;
  valor6: string;
  ano7: string;
  valor7: string;
  ano8: string;
  valor8: string;
  ano9: string;
  valor9: string;
  ano10: string;
  valor10: string;
}

export interface Maquina {
  id: number;
  descricao: string;
  marca: string;
  modelo: string;
  tipo: string;
  ano_fabricacao: string;
  ano_aquisicao: string;
  placa: string;
  valor_aquisicao: string;
  valor_atual: string;
  vida_util: string;
  depreciacao_anual: string;
  idFazenda: number;
  ano1: string;
  valor1: string;
  ano2: string;
  valor2: string;
  ano3: string;
  valor3: string;
  ano4: string;
  valor4: string;
  ano5: string;
  valor5: string;
  ano6: string;
  valor6: string;
  ano7: string;
  valor7: string;
  ano8: string;
  valor8: string;
  ano9: string;
  valor9: string;
  ano10: string;
  valor10: string;
}

interface InscricaoEstadualState {
  id: number | null;
  idFazenda: number;
  uf: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  inscricao_estadual: string;
  maxlength?: number;
  matricula: string;
}

interface TalhaoState {
  id: number | null;
  idFazenda: number;
  nome: string;
  area: string;
}

interface Municipio {
  cod_municipio: number;
  nome_municipio: string;
  estado: string;
}

const FazendasPage: React.FC = () => {
  
  const navigate = useNavigate();
  
  const [show, setShow] = useState(false);
  const [fazendas, setFazendas] = useState<Fazenda[]>([]);
  const [producoes, setProducoes] = useState<Producao[]>([]);
  const [newFazenda, setNewFazenda] = useState({ id: null, nome: '', valor_hectare: '', area_total_hectare: '', area_preservacao_permanente: '', area_reserva_ambiental: '', area_util: '', status: '1', arrendada: '0' });
  const [isEditing, setIsEditing] = useState(false);
  const [alerta, setAlerta] = useState('');
  const initialFazendaState = { id: null, nome: '', valor_hectare: '', area_total_hectare: '', area_preservacao_permanente: '', area_reserva_ambiental: '', area_util: '', status: '1', arrendada: '0' };
  const [search, setSearch] = useState('');
  const [inscricoes, setInscricoes] = useState<InscricaoEstadual[]>([]);
  const [talhoes, setTalhoes] = useState<Talhao[]>([]);
  const [maquinas, setMaquinas] = useState<Maquina[]>([]);
  const [disabledInscricao, setDisabledInscricao] = useState(true);
  const [inscricaoPlaceholder, setInscricaoPlaceholder] = useState<string>("Selecione o Estado");
  const [idFazendaSel, setIdFazendaSel] = useState(0);
  const [ufComboSel, setUfComboSel] = useState('');
  const [somatorioAreasTalhoes, setSomatorioAreasTalhoes] = useState(0);
  
  const [unidadeProducaoSel, setUnidadeProducaoSel] = useState('');
  const [talhaoAreaSelecionado, setTalhaoAreaSelecionado] = useState(0);

  const [hectareTalhaoProducaoSel, setHectareTalhaoProducaoSel] = useState(0);

  const [btEditaTalhao, setBtEditaTalhao] = useState(false);
  const [btEditaInscricao, setBtEditaInscricao] = useState(false);
  const [btEditaMaquina, setBtEditaMaquina] = useState(false);
  const [btEditaProducao, setBtEditaProducao] = useState(false);
  
  const [isEditingMaquina, setIsEditingMaquina] = useState(false);
  const [isEditingTalhao, setIsEditingTalhao] = useState(false);
  const [isEditingInscricao, setIsEditingInscricao] = useState(false);
  const [isEditingProducao, setIsEditingProducao] = useState(false);
  
  const [checkPrimeiroValor, setCheckPrimeiroValor] = useState(true);

  const [contadorLista, setContadorLista] = useState(1);

  const [listaSegundo, setListaSegundo] = useState(false);
  const [checkSegundoValor, setCheckSegundoValor] = useState(true);
  const [listaSegundoGanhoPerca, setListaSegundoGanhoPerca] = useState(0);
  const [listaSegundoGanhoPercaColor, setListaSegundoGanhoPercaColor] = useState('text-success');
  
  const [listaTerceiro, setListaTerceiro] = useState(false);
  const [checkTerceiroValor, setCheckTerceiroValor] = useState(true);
  const [listaTerceiroGanhoPerca, setListaTerceiroGanhoPerca] = useState(0);
  const [listaTerceiroGanhoPercaColor, setListaTerceiroGanhoPercaColor] = useState('text-success');
  
  const [listaQuarto, setListaQuarto] = useState(false);
  const [checkQuartoValor, setCheckQuartoValor] = useState(true);
  const [listaQuartoGanhoPerca, setListaQuartoGanhoPerca] = useState(0);
  const [listaQuartoGanhoPercaColor, setListaQuartoGanhoPercaColor] = useState('text-success');
  
  const [listaQuinto, setListaQuinto] = useState(false);
  const [checkQuintoValor, setCheckQuintoValor] = useState(true);
  const [listaQuintoGanhoPerca, setListaQuintoGanhoPerca] = useState(0);
  const [listaQuintoGanhoPercaColor, setListaQuintoGanhoPercaColor] = useState('text-success');
  
  const [listaSexto, setListaSexto] = useState(false);
  const [checkSextoValor, setCheckSextoValor] = useState(true);
  const [listaSextoGanhoPerca, setListaSextoGanhoPerca] = useState(0);
  const [listaSextoGanhoPercaColor, setListaSextoGanhoPercaColor] = useState('text-success');
  
  const [listaSetimo, setListaSetimo] = useState(false);
  const [checkSetimoValor, setCheckSetimoValor] = useState(true);
  const [listaSetimoGanhoPerca, setListaSetimoGanhoPerca] = useState(0);
  const [listaSetimoGanhoPercaColor, setListaSetimoGanhoPercaColor] = useState('text-success');
  
  const [listaOitavo, setListaOitavo] = useState(false);
  const [checkOitavoValor, setCheckOitavoValor] = useState(true);
  const [listaOitavoGanhoPerca, setListaOitavoGanhoPerca] = useState(0);
  const [listaOitavoGanhoPercaColor, setListaOitavoGanhoPercaColor] = useState('text-success');
  
  const [listaNono, setListaNono] = useState(false);
  const [checkNonoValor, setCheckNonoValor] = useState(true);
  const [listaNonoGanhoPerca, setListaNonoGanhoPerca] = useState(0);
  const [listaNonoGanhoPercaColor, setListaNonoGanhoPercaColor] = useState('text-success');
  
  const [listaDecimo, setListaDecimo] = useState(false);
  const [checkDecimoValor, setCheckDecimoValor] = useState(true);
  const [listaDecimoGanhoPerca, setListaDecimoGanhoPerca] = useState(0);
  const [listaDecimoGanhoPercaColor, setListaDecimoGanhoPercaColor] = useState('text-success');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    const { value } = e.target;
    
    setNewMaquina(prevState => ({
      ...prevState,
      [field]: value
    }));
  };
  
  const [newTalhao, setNewTalhao] = useState<TalhaoState>({
    id: null,
    idFazenda: 0,
    nome: '',
    area: '0,00'
  });
  
  const [newProducao, setNewProducao] = useState<ProducaoState>({
    id: null,
    produto: '',
    subProduto: '',
    armazenamento: '',
    unidade: '',
    dataPlantio: '',
    produtividade: 0,
    valorUnitario: '',
    dataColheita: '',
    variedadeProduto: '',
    cicloEstimado: '',
    dataColheitaEstimada: '',
    valorTotal: '',
    safra: '',
    talhao: '',
    idFazenda: 0
  })

  const [newMaquina, setNewMaquina] = useState<MaquinaState>({
    id: null,
    descricao: '',
    marca: '',
    modelo: '',
    tipo: '',
    ano_fabricacao: '',
    ano_aquisicao: '',
    placa: '',
    valor_aquisicao: '',
    valor_atual: '',
    vida_util: '',
    depreciacao_anual: '',
    valor_ultimo_exercicio: '',
    idFazenda: 0,
    ano1: '',
    valor1: '',
    ano2: '',
    valor2: '',
    ano3: '',
    valor3: '',
    ano4: '',
    valor4: '',
    ano5: '',
    valor5: '',
    ano6: '',
    valor6: '',
    ano7: '',
    valor7: '',
    ano8: '',
    valor8: '',
    ano9: '',
    valor9: '',
    ano10: '',
    valor10: ''
  });
  
  const [newInscricaoEstadual, setNewInscricaoEstadual] = useState<InscricaoEstadualState>({
    id: null,
    idFazenda: 0,
    uf: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    inscricao_estadual: '',
    matricula: ''
  });
  
  const handleClose = () => setShow(false);
  
  const add = () => {
    setNewFazenda(initialFazendaState);
    setIsEditing(false);
    setShow(true);
  };

  const addProximaLinha = () => {

    if(contadorLista == 2){

      setListaSegundo(true);
    }

    if(contadorLista == 3){

      setListaTerceiro(true);
    }   
  }

  const formatToBRL = (value:any) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  }

  const addPrimeiraLinha = () => {
    
    setAlerta('');

    if (newMaquina.ano1 === '' || newMaquina.valor1 === '') {
      setAlerta('Preencha o Ano de aquisição e o Valor corretamente.');
    } else {
      
      setAlerta('');
      setCheckPrimeiroValor(false);
      setContadorLista(2);
      setListaSegundo(true);
      
    }
  };

  const addSegundaLinha = () => {
    setAlerta('');
  
    if (newMaquina.ano2 === '' || newMaquina.valor2 === '') {
      setAlerta('Preencha o Ano e o Valor corretamente.');
    } else {
      if (convertToInteger(newMaquina.valor1) >= convertToInteger(newMaquina.valor2)) {
        let resultSegundoGanhoPerca = convertToInteger(newMaquina.valor1) - convertToInteger(newMaquina.valor2);
        setListaSegundoGanhoPerca(resultSegundoGanhoPerca);
        setListaSegundoGanhoPercaColor('text-danger');
      } else {
        let resultSegundoGanhoPerca = convertToInteger(newMaquina.valor2) - convertToInteger(newMaquina.valor1);
        setListaSegundoGanhoPerca(resultSegundoGanhoPerca);
        setListaSegundoGanhoPercaColor('text-success');
      }
  
      setAlerta('');
      setCheckSegundoValor(false);
      setContadorLista(3);
    }
  };
  
  const addTerceiraLinha = () => {
    setAlerta('');
  
    if (newMaquina.ano3 === '' || newMaquina.valor3 === '') {
      setAlerta('Preencha o Ano e o Valor corretamente.');
    } else {
      if (convertToInteger(newMaquina.valor2) >= convertToInteger(newMaquina.valor3)) {
        let resultTerceiroGanhoPerca = convertToInteger(newMaquina.valor2) - convertToInteger(newMaquina.valor3);
        setListaTerceiroGanhoPerca(resultTerceiroGanhoPerca);
        setListaTerceiroGanhoPercaColor('text-danger');
      } else {
        let resultTerceiroGanhoPerca = convertToInteger(newMaquina.valor3) - convertToInteger(newMaquina.valor2);
        setListaTerceiroGanhoPerca(resultTerceiroGanhoPerca);
        setListaTerceiroGanhoPercaColor('text-success');
      }
  
      setListaQuarto(true);
      setAlerta('');
      setCheckTerceiroValor(false);
    }
  };
  
  const addQuartaLinha = () => {
    setAlerta('');
  
    if (newMaquina.ano4 === '' || newMaquina.valor4 === '') {
      setAlerta('Preencha o Ano e o Valor corretamente.');
    } else {
      if (convertToInteger(newMaquina.valor3) >= convertToInteger(newMaquina.valor4)) {
        let resultQuartoGanhoPerca = convertToInteger(newMaquina.valor3) - convertToInteger(newMaquina.valor4);
        setListaQuartoGanhoPerca(resultQuartoGanhoPerca);
        setListaQuartoGanhoPercaColor('text-danger');
      } else {
        let resultQuartoGanhoPerca = convertToInteger(newMaquina.valor4) - convertToInteger(newMaquina.valor3);
        setListaQuartoGanhoPerca(resultQuartoGanhoPerca);
        setListaQuartoGanhoPercaColor('text-success');
      }
  
      setListaQuinto(true);
      setAlerta('');
      setCheckQuartoValor(false);
    }
  };

  const addQuintaLinha = () => {
    setAlerta('');
  
    if (newMaquina.ano5 === '' || newMaquina.valor5 === '') {
      setAlerta('Preencha o Ano e o Valor corretamente.');
    } else {
      if (convertToInteger(newMaquina.valor4) >= convertToInteger(newMaquina.valor5)) {
        let resultQuintoGanhoPerca = convertToInteger(newMaquina.valor4) - convertToInteger(newMaquina.valor5);
        setListaQuintoGanhoPerca(resultQuintoGanhoPerca);
        setListaQuintoGanhoPercaColor('text-danger');
      } else {
        let resultQuintoGanhoPerca = convertToInteger(newMaquina.valor5) - convertToInteger(newMaquina.valor4);
        setListaQuintoGanhoPerca(resultQuintoGanhoPerca);
        setListaQuintoGanhoPercaColor('text-success');
      }
  
      setListaSexto(true);
      setAlerta('');
      setCheckQuintoValor(false);
    }
  };

  const addSextaLinha = () => {
    setAlerta('');
  
    if (newMaquina.ano6 === '' || newMaquina.valor6 === '') {
      setAlerta('Preencha o Ano e o Valor corretamente.');
    } else {
      if (convertToInteger(newMaquina.valor5) >= convertToInteger(newMaquina.valor6)) {
        let resultSextoGanhoPerca = convertToInteger(newMaquina.valor5) - convertToInteger(newMaquina.valor6);
        setListaSextoGanhoPerca(resultSextoGanhoPerca);
        setListaSextoGanhoPercaColor('text-danger');
      } else {
        let resultSextoGanhoPerca = convertToInteger(newMaquina.valor6) - convertToInteger(newMaquina.valor5);
        setListaSextoGanhoPerca(resultSextoGanhoPerca);
        setListaSextoGanhoPercaColor('text-success');
      }
  
      setListaSetimo(true);
      setAlerta('');
      setCheckSextoValor(false);
    }
  };
  
  const addSetimaLinha = () => {
    setAlerta('');
  
    if (newMaquina.ano7 === '' || newMaquina.valor7 === '') {
      setAlerta('Preencha o Ano e o Valor corretamente.');
    } else {
      if (convertToInteger(newMaquina.valor6) >= convertToInteger(newMaquina.valor7)) {
        let resultSetimoGanhoPerca = convertToInteger(newMaquina.valor6) - convertToInteger(newMaquina.valor7);
        setListaSetimoGanhoPerca(resultSetimoGanhoPerca);
        setListaSetimoGanhoPercaColor('text-danger');
      } else {
        let resultSetimoGanhoPerca = convertToInteger(newMaquina.valor7) - convertToInteger(newMaquina.valor6);
        setListaSetimoGanhoPerca(resultSetimoGanhoPerca);
        setListaSetimoGanhoPercaColor('text-success');
      }
  
      setListaOitavo(true);
      setAlerta('');
      setCheckSetimoValor(false);
    }
  };
  
  const addOitavaLinha = () => {
    setAlerta('');
  
    if (newMaquina.ano8 === '' || newMaquina.valor8 === '') {
      setAlerta('Preencha o Ano e o Valor corretamente.');
    } else {
      if (convertToInteger(newMaquina.valor7) >= convertToInteger(newMaquina.valor8)) {
        let resultOitavoGanhoPerca = convertToInteger(newMaquina.valor7) - convertToInteger(newMaquina.valor8);
        setListaOitavoGanhoPerca(resultOitavoGanhoPerca);
        setListaOitavoGanhoPercaColor('text-danger');
      } else {
        let resultOitavoGanhoPerca = convertToInteger(newMaquina.valor8) - convertToInteger(newMaquina.valor7);
        setListaOitavoGanhoPerca(resultOitavoGanhoPerca);
        setListaOitavoGanhoPercaColor('text-success');
      }
  
      setListaNono(true);
      setAlerta('');
      setCheckOitavoValor(false);
    }
  };
  
  const addNonaLinha = () => {
    setAlerta('');
  
    if (newMaquina.ano9 === '' || newMaquina.valor9 === '') {
      setAlerta('Preencha o Ano e o Valor corretamente.');
    } else {
      if (convertToInteger(newMaquina.valor8) >= convertToInteger(newMaquina.valor9)) {
        let resultNonoGanhoPerca = convertToInteger(newMaquina.valor8) - convertToInteger(newMaquina.valor9);
        setListaNonoGanhoPerca(resultNonoGanhoPerca);
        setListaNonoGanhoPercaColor('text-danger');
      } else {
        let resultNonoGanhoPerca = convertToInteger(newMaquina.valor9) - convertToInteger(newMaquina.valor8);
        setListaNonoGanhoPerca(resultNonoGanhoPerca);
        setListaNonoGanhoPercaColor('text-success');
      }
  
      setListaDecimo(true);
      setAlerta('');
      setCheckNonoValor(false);
    }
  };
  
  const addDecimaLinha = () => {
    setAlerta('');
  
    if (newMaquina.ano10 === '' || newMaquina.valor10 === '') {
      setAlerta('Preencha o Ano e o Valor corretamente.');
    } else {
      if (convertToInteger(newMaquina.valor9) >= convertToInteger(newMaquina.valor10)) {
        let resultDecimoGanhoPerca = convertToInteger(newMaquina.valor9) - convertToInteger(newMaquina.valor10);
        setListaDecimoGanhoPerca(resultDecimoGanhoPerca);
        setListaDecimoGanhoPercaColor('text-danger');
      } else {
        let resultDecimoGanhoPerca = convertToInteger(newMaquina.valor10) - convertToInteger(newMaquina.valor9);
        setListaDecimoGanhoPerca(resultDecimoGanhoPerca);
        setListaDecimoGanhoPercaColor('text-success');
      }
  
      setAlerta('');
      setCheckDecimoValor(false);
    }
  };
  
  const removeSegundaLinha = async () => {

    newMaquina.valor2 = '';
    setListaSegundoGanhoPerca(0);

    setCheckPrimeiroValor(true);
    setCheckSegundoValor(true);
    setListaSegundo(false);
    setContadorLista(2);
  }

  const removeTerceiraLinha = async () => {
    
    newMaquina.valor3 = '';
    
    setCheckTerceiroValor(true);
    setListaTerceiro(false);
    setContadorLista(3);
  }
  
  const removeQuartaLinha = async () => {

    newMaquina.valor4 = '';

    setCheckQuartoValor(true);
    setListaQuarto(false);
    setContadorLista(4);
  }
  
  const removeQuintaLinha = async () => {

    newMaquina.valor5 = '';

    setCheckQuintoValor(true);
    setListaQuinto(false);
    setContadorLista(5);
  }
  
  const removeSextaLinha = async () => {

    newMaquina.valor6 = '';

    setCheckSextoValor(true);
    setListaSexto(false);
    setContadorLista(6);
  }
  
  const removeSetimaLinha = async () => {

    newMaquina.valor7 = '';

    setCheckSetimoValor(true);
    setListaSetimo(false);
    setContadorLista(7);
  }
  
  const removeOitavaLinha = async () => {

    newMaquina.valor8 = '';

    setCheckOitavoValor(true);
    setListaOitavo(false);
    setContadorLista(8);
  }
  
  const removeNonaLinha = async () => {
    
    newMaquina.valor9 = '';
    
    setCheckNonoValor(true);
    setListaNono(false);
    setContadorLista(9);
  }
  
  const removeDecimaLinha = async () => {

    newMaquina.valor10 = '';

    setCheckDecimoValor(true);
    setListaDecimo(false);
    setContadorLista(10);
  }

  const addInscricaoEstadual = async () => {
    setAlerta('');
    newInscricaoEstadual.idFazenda = idFazendaSel;
    let result = await createInscricaoEstadual(newInscricaoEstadual);
    if (result) {
      if (result.status == 'error') {
        setAlerta(result.messages[0]);  
      }
      if (result.status == 'success') {
        setNewInscricaoEstadual({
          id: null,
          idFazenda: 0,
          uf: '',
          cep: '',
          endereco: '',
          numero: '',
          complemento: '',
          bairro: '',
          cidade: '',
          inscricao_estadual: '',
          matricula: ''
        });
        fetchInscricoesEstaduais(idFazendaSel);
        setAlerta('Inscrição estadual cadastrada com sucesso.');
      }
    }
  }
  
  const addMaquina = async () => {
    setAlerta('');
    newMaquina.idFazenda = idFazendaSel;
    let result = await createMaquina(newMaquina);
    if (result) {
      if (result.status == 'error') {
        setAlerta(result.messages[0]);  
      }
      if (result.status == 'success') {
        setNewMaquina({
          id: null,
          descricao: '',
          marca: '',
          modelo: '',
          tipo: '',
          ano_fabricacao: '',
          ano_aquisicao: '',
          placa: '',
          valor_aquisicao: '',
          valor_atual: '',
          vida_util: '',
          depreciacao_anual: '',
          valor_ultimo_exercicio: '',
          idFazenda: 0,
          ano1: '',
          valor1: '',
          ano2: '',
          valor2: '',
          ano3: '',
          valor3: '',
          ano4: '',
          valor4: '',
          ano5: '',
          valor5: '',
          ano6: '',
          valor6: '',
          ano7: '',
          valor7: '',
          ano8: '',
          valor8: '',
          ano9: '',
          valor9: '',
          ano10: '',
          valor10: ''
        });
        
        fetchMaquinas(idFazendaSel);
        setAlerta('Máquina/Benfeitoria cadastrada com sucesso.');

        setListaSegundo(false);
        setListaTerceiro(false);
        setListaQuarto(false);
        setListaQuinto(false);
        setListaSexto(false);
        setListaSetimo(false);
        setListaOitavo(false);
        setListaNono(false);
        setListaDecimo(false);

        setCheckPrimeiroValor(true);
        setCheckSegundoValor(false);
        setCheckTerceiroValor(false);
        setCheckQuartoValor(false);
        setCheckQuintoValor(false);
        setCheckSextoValor(false);
        setCheckSetimoValor(false);
        setCheckOitavoValor(false);
        setCheckNonoValor(false);
        setCheckDecimoValor(false);
        
        setCheckSegundoValor(true);
        setCheckTerceiroValor(true);
        setCheckQuartoValor(true);
        setCheckQuintoValor(true);
        setCheckSextoValor(true);
        setCheckSetimoValor(true);
        setCheckOitavoValor(true);
        setCheckNonoValor(true);
        setCheckDecimoValor(true);

        setContadorLista(1);
      }
    }
  }
  
  const cancelaInscricaoEstadual = () => {
    
    setNewInscricaoEstadual({
      id: null,
      idFazenda: 0,
      cep: '',
      cidade: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      uf: '',
      matricula: '',
      inscricao_estadual: ''
    });
    
    setBtEditaInscricao(false);    
  }
  
  const cancelaTalhao = () => {
    
    setNewTalhao({
      id: null,
      idFazenda: 0,
      nome: '',
      area: '',
    });
    
    setBtEditaTalhao(false);    
  }
  
  const cancelaProducao = () => {

    setNewProducao({
      id: null,
      produto: '',
      subProduto: '',
      armazenamento: '',
      unidade: '',
      dataPlantio: '',
      produtividade: 0,
      valorUnitario: '',
      dataColheita: '',
      variedadeProduto: '',
      cicloEstimado: '',
      dataColheitaEstimada: '',
      valorTotal: '',
      safra: '',
      talhao: '',
      idFazenda: 0
    });
    
    setBtEditaProducao(false); 
  }

  const addProducao = async () => {
    
    setAlerta('');  
    
    newProducao.id = null;
    newProducao.idFazenda = idFazendaSel;  

    let result = await createProducao(newProducao); 
    
    if (result) {
      if (result.status === 'error') {
      
        setAlerta(result.messages[0]);
      }
      
      if (result.status === 'success') {
      
        setNewProducao({
          id: null,
          produto: '',
          subProduto: '',
          armazenamento: '',
          unidade: '',
          dataPlantio: '',
          produtividade: 0,
          valorUnitario: '',
          dataColheita: '',
          variedadeProduto: '',
          cicloEstimado: '',
          dataColheitaEstimada: '',
          valorTotal: '',
          safra: '',
          talhao: '',
          idFazenda: 0
        });
        
        fetchProducoes(idFazendaSel); 
        
        setAlerta('Produção cadastrada com sucesso.');
      }
    }
  };
  
  const cancelaMaquina = () => {
    
    setNewMaquina({
      id: null,
      descricao: '',
      marca: '',
      modelo: '',
      tipo: '',
      ano_fabricacao: '',
      ano_aquisicao: '',
      placa: '',
      valor_aquisicao: '',
      valor_atual: '',
      vida_util: '',
      depreciacao_anual: '',
      valor_ultimo_exercicio: '',
      idFazenda: 0,
      ano1: '',
      valor1: '',
      ano2: '',
      valor2: '',
      ano3: '',
      valor3: '',
      ano4: '',
      valor4: '',
      ano5: '',
      valor5: '',
      ano6: '',
      valor6: '',
      ano7: '',
      valor7: '',
      ano8: '',
      valor8: '',
      ano9: '',
      valor9: '',
      ano10: '',
      valor10: ''
    });
    
    setListaSegundo(false);
    setListaTerceiro(false);
    setListaQuarto(false);
    setListaQuinto(false);
    setListaSexto(false);
    setListaSetimo(false);
    setListaOitavo(false);
    setListaNono(false);
    setListaDecimo(false);

    setBtEditaMaquina(false);   

    setCheckPrimeiroValor(true);
    setCheckSegundoValor(true);
    setCheckTerceiroValor(true);
    setCheckDecimoValor(true);
    setCheckQuintoValor(true);
    setCheckSextoValor(true);
    setCheckSetimoValor(true);
    setCheckOitavoValor(true);
    setCheckNonoValor(true);
    setCheckDecimoValor(true);
  }

  const editaTalhao = async () => {
    
    setAlerta('');
    
    let somatorioAreasTalhoes: number = 0;
    
    talhoes.forEach(talhao => {
      somatorioAreasTalhoes += (parseFloat(talhao.area.replace(/\./g, '').replace('.', '').replace(',', '.')));
    });
    
    const areaUtil = convertToInteger(newFazenda.area_util);
    
    let areaTalhaoCampo = newTalhao.area;
    
    const areaTalhaoCampoNumero: number = parseFloat(areaTalhaoCampo.replace(/\./g, '').replace('.', '').replace(',', '.'));
    
    somatorioAreasTalhoes += areaTalhaoCampoNumero;
    
    let soma1 = (somatorioAreasTalhoes + parseFloat(areaTalhaoCampo));
    let soma2 = parseFloat(areaUtil.toString());
    
    if(soma1 > soma2){
      
      setAlerta('A área total da fazenda não deve ser menor do que o somatório das áreas dos talhões.');
      
      setTimeout(() => {
        setAlerta('');
      }, 0);
    }else{
      
      const update = await updateTalhao(newTalhao);
      
      if(update){
        
        setAlerta('Talhão alterado com sucesso.');
        fetchTalhoes(newTalhao.idFazenda);
        
        setNewTalhao({
          id: null,
          idFazenda: 0,
          nome: '',
          area: '',
        });
        
        setBtEditaTalhao(false);
      }
    }
  }
  
  const editaMaquina = async () => {

    setAlerta('');
  }
  
  const editaProducao = async () => {
    
    setAlerta('');
      
      const update = await updateProducao(newProducao);
      
      if(update){
        
        setAlerta('Produção alterada com sucesso.');
        fetchProducoes(newProducao.idFazenda);
        
        setNewProducao({
          id: null,
          produto: '',
          subProduto: '',
          armazenamento: '',
          unidade: '',
          dataPlantio: '',
          produtividade: 0,
          valorUnitario: '',
          dataColheita: '',
          variedadeProduto: '',
          cicloEstimado: '',
          dataColheitaEstimada: '',
          valorTotal: '',
          safra: '',
          talhao: '',
          idFazenda: 0
        });
        
        setBtEditaProducao(false);
      }
  }

  const editaInscricaoEstadual = async () => {
    
    setAlerta('');
    
    const update = await updateInscricaoEstadual(newInscricaoEstadual);
    
    if(update){
      
      setAlerta('Inscrição Estadual alterada com sucesso.');
      fetchInscricoesEstaduais(newInscricaoEstadual.idFazenda);
      
      setNewInscricaoEstadual({
        id: null,
        idFazenda: 0,
        uf: '',
        cep: '',
        cidade: '',
        inscricao_estadual: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        matricula: ''
      });
      
      setBtEditaInscricao(false);
    }
  }
  
  const addTalhao = async () => {
    
    setAlerta('');
    
    let somatorioAreasTalhoes: number = 0;
    
    talhoes.forEach(talhao => {
      somatorioAreasTalhoes += (parseFloat(talhao.area.replace(/\./g, '').replace('.', '').replace(',', '.')));
    });
    
    const areaUtil = convertToInteger(newFazenda.area_util);
    
    let areaTalhaoCampo = newTalhao.area;
    
    const areaTalhaoCampoNumero: number = parseFloat(areaTalhaoCampo.replace(/\./g, '').replace('.', '').replace(',', '.'));
    
    somatorioAreasTalhoes += areaTalhaoCampoNumero;
    
    if(parseFloat(somatorioAreasTalhoes.toString() + parseFloat(areaTalhaoCampo)) > parseFloat(areaUtil.toString())){
      
      setAlerta('A área total da fazenda não deve ser menor do que o somatório das áreas dos talhões.');
      
      setTimeout(() => {
        setAlerta('');
      }, 0);
    }else{
      
      newTalhao.idFazenda = idFazendaSel;
      let result = await createTalhao(newTalhao);
      if (result) {
        if (result.status == 'error') {
          setAlerta(result.messages[0]);  
        }
        if (result.status == 'success') {
          setNewTalhao({
            id: null,
            idFazenda: 0,
            nome: '',
            area: '0,00'
          });
          fetchTalhoes(idFazendaSel);
          setAlerta('Talhão cadastrado com sucesso.');
        }
      }
    }
  }
  
  const removeMaquina = async (id: number) => {
    let result = await deleteMaquina(id);
    if (result) {
      if (result.status == 'error') {
        setAlerta(result.message[0]);  
      }
      if (result.status == 'success') {
        fetchMaquinas(idFazendaSel);
        setAlerta('Máquina/Benfeitoria removida com sucesso.');
      }
    }
  }

  const removeTalhao = async (id: number) => {
    let result = await deleteTalhao(id);
    if (result) {
      if (result.status == 'error') {
        setAlerta(result.message[0]);  
      }
      if (result.status == 'success') {
        fetchTalhoes(idFazendaSel);
        setAlerta('Talhão removido com sucesso.');
      }
    }
  }

  const removeProducao = async (id: number) => {
    let result = await deleteProducao(id);
    if (result) {
      if (result.status == 'error') {
        setAlerta(result.message[0]);  
      }
      if (result.status == 'success') {
        fetchProducoes(idFazendaSel);
        setAlerta('Produção removida com sucesso.');
      }
    }
  }
  
  const removeInscricao = async (id: number) => {
    let result = await deleteInscricaoEstadual(id);
    if (result) {
      if (result.status == 'error') {
        setAlerta(result.message[0]);  
      }
      if (result.status == 'success') {
        fetchInscricoesEstaduais(idFazendaSel);
        setAlerta('Inscrição estadual removida com sucesso.');
      }
    }
  }
  
  const fetchAddress = async (cep: string) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (!data.erro) {
        setNewInscricaoEstadual((prevInscricaoEstadual) => ({
          ...prevInscricaoEstadual,
          endereco: data.logradouro,
          complemento: data.complemento,
          bairro: data.bairro,
          cidade: data.localidade,
        }));
      } else {
        setAlerta('CEP não encontrado.');
      }
    } catch (error) {
      setAlerta('Erro ao buscar endereço. Tente novamente.');
    }
  };
  
  const edit = (id: number) => {
    
    navigate(`/fazenda/${id}`);
  };

  const editOld = async (id: number) => {
    try {
      setNewInscricaoEstadual({
        id: null,
        idFazenda: 0,
        uf: '',
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        inscricao_estadual: '',
        matricula: ''
      });
      
      const fazenda = await getFazendaById(id);
      
      if(fazenda && isEditing === false){
        
        setDisabledInscricao(true);
        setIdFazendaSel(id);
        fetchInscricoesEstaduais(id);
        fetchTalhoes(id);
        fetchMaquinas(id);
        fetchProducoes(id); 
        setNewFazenda({ id: fazenda.id, nome: fazenda.nome, valor_hectare: fazenda.valor_hectare, area_total_hectare: fazenda.area_total_hectare, area_preservacao_permanente: fazenda.area_preservacao_permanente, area_reserva_ambiental: fazenda.area_reserva_ambiental, area_util: fazenda.area_util, status: fazenda.status, arrendada: fazenda.arrendada });
        setIsEditing(true);
        setShow(true);
      }
    } catch (error) {
      console.error('Error fetching fazenda:', error);
    }
  };
  
  const notify = () => toast.info(alerta);
  
  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);
  
  const fetchFazendas = async (search = '') => {
    try {
      
      if(isEditing === false){
        
        const fazendas = await getFazendas(search);
        const idUser = Cookies.get('TokenBrasao');
        const filteredFazendas = fazendas.filter((fazenda: Fazenda) => fazenda.idUser == idUser);
        setFazendas(filteredFazendas);
      }
    } catch (error) {
      console.error('Error fetching fazendas:', error);
    }
  };
  
  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === '' || search.trim().length >= 3) {
      fetchFazendas(search.trim());
    } else {
      setAlerta('O termo de pesquisa deve ter pelo menos 3 caracteres.');
    }
  };
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === '') {
      fetchFazendas('');
    }
  };

  const detalheMaquina = async (id:any) => {

    try{

      setListaSegundoGanhoPerca(0);
      setListaTerceiroGanhoPerca(0);
      setListaQuartoGanhoPerca(0);
      setListaQuintoGanhoPerca(0);
      setListaSextoGanhoPerca(0);
      setListaSetimoGanhoPerca(0);
      setListaOitavoGanhoPerca(0);
      setListaNonoGanhoPerca(0);
      setListaDecimoGanhoPerca(0);      

      setListaSegundo(false);
      setListaTerceiro(false);
      setListaQuarto(false);
      setListaQuinto(false);
      setListaSexto(false);
      setListaSetimo(false);
      setListaOitavo(false);
      setListaNono(false);
      setListaDecimo(false);

      setCheckSegundoValor(false);

      const detalhe = await getMaquinaById(id);

      if(detalhe){

        let detValor1 = detalhe.valor1;
        let detValor2 = detalhe.valor2;
        let detValor3 = detalhe.valor3;
        let detValor4 = detalhe.valor4;
        let detValor5 = detalhe.valor5;
        let detValor6 = detalhe.valor6;
        let detValor7 = detalhe.valor7;
        let detValor8 = detalhe.valor8;
        let detValor9 = detalhe.valor9;
        let detValor10 = detalhe.valor10;
        
        if(detValor1 !== '') detValor1 = formatToBRL(detValor1);
        if(detValor2 !== '') detValor2 = formatToBRL(detValor2);
        if(detValor3 !== '') detValor3 = formatToBRL(detValor3);
        if(detValor4 !== '') detValor4 = formatToBRL(detValor4);
        if(detValor5 !== '') detValor5 = formatToBRL(detValor5);
        if(detValor6 !== '') detValor6 = formatToBRL(detValor6);
        if(detValor7 !== '') detValor7 = formatToBRL(detValor7);
        if(detValor8 !== '') detValor8 = formatToBRL(detValor8);
        if(detValor9 !== '') detValor9 = formatToBRL(detValor9);
        if(detValor10 !== '') detValor10 = formatToBRL(detValor10);
        
        setNewMaquina({
          id: detalhe.id,
          descricao: detalhe.descricao,
          marca: detalhe.marca,
          modelo: detalhe.modelo,
          tipo: detalhe.tipo,
          ano_fabricacao: detalhe.ano_fabricacao,
          ano_aquisicao: detalhe.ano_aquisicao,
          placa: detalhe.placa,
          valor_aquisicao: detalhe.valor_aquisicao,
          valor_atual: detalhe.valor_atual,
          vida_util: detalhe.vida_util,
          depreciacao_anual: detalhe.depreciacao_anual,
          valor_ultimo_exercicio: detalhe.valor_ultimo_exercicio,
          idFazenda: detalhe.idFazenda,
          ano1: detalhe.ano1,
          valor1: detValor1,
          ano2: detalhe.ano2,
          valor2: detValor2,
          ano3: detalhe.ano3,
          valor3: detValor3,
          ano4: detalhe.ano4,
          valor4: detValor4,
          ano5: detalhe.ano5,
          valor5: detValor5,
          ano6: detalhe.ano6,
          valor6: detValor6,
          ano7: detalhe.ano7,
          valor7: detValor7,
          ano8: detalhe.ano8,
          valor8: detValor8,
          ano9: detalhe.ano9,
          valor9: detValor9,
          ano10: detalhe.ano10,
          valor10: detValor10
        });        

        if (detalhe.valor2 !== null) {

          setListaSegundo(true);
          setContadorLista(3);
          
          if (convertToInteger(detalhe.valor1) >= convertToInteger(detalhe.valor2)) {
            let resultSegundoGanhoPerca = (detalhe.valor1) - (detalhe.valor2);
            setListaSegundoGanhoPerca(resultSegundoGanhoPerca);
            setListaSegundoGanhoPercaColor('text-danger');
        
          } else {
            let resultSegundoGanhoPerca = (detalhe.valor2) - (detalhe.valor1);
            setListaSegundoGanhoPerca(resultSegundoGanhoPerca);
            setListaSegundoGanhoPercaColor('text-success');
          }
        }
        
        if (detalhe.valor3 !== null) {
        
          setListaTerceiro(true);
          setContadorLista(4);
        
          if (convertToInteger(detalhe.valor2) >= convertToInteger(detalhe.valor3)) {
            let resultTerceiroGanhoPerca = (detalhe.valor2) - (detalhe.valor3);
            setListaTerceiroGanhoPerca(resultTerceiroGanhoPerca);
            setListaTerceiroGanhoPercaColor('text-danger');
          
          } else {
            let resultTerceiroGanhoPerca = (detalhe.valor3) - (detalhe.valor2);
            setListaTerceiroGanhoPerca(resultTerceiroGanhoPerca);
            setListaTerceiroGanhoPercaColor('text-success');
          }
        }
        
        if (detalhe.valor4 !== null) {
        
          setListaQuarto(true);
          setContadorLista(5);
        
          if (convertToInteger(detalhe.valor3) >= convertToInteger(detalhe.valor4)) {
            let resultQuartoGanhoPerca = (detalhe.valor3) - (detalhe.valor4);
            setListaQuartoGanhoPerca(resultQuartoGanhoPerca);
            setListaQuartoGanhoPercaColor('text-danger');
          
          } else {
            let resultQuartoGanhoPerca = (detalhe.valor4) - (detalhe.valor3);
            setListaQuartoGanhoPerca(resultQuartoGanhoPerca);
            setListaQuartoGanhoPercaColor('text-success');
          }
        }
        
        if (detalhe.valor5 !== null) {
        
          setListaQuinto(true);
          setContadorLista(6);
        
          if (convertToInteger(detalhe.valor4) >= convertToInteger(detalhe.valor5)) {
            let resultQuintoGanhoPerca = (detalhe.valor4) - (detalhe.valor5);
            setListaQuintoGanhoPerca(resultQuintoGanhoPerca);
            setListaQuintoGanhoPercaColor('text-danger');
          
          } else {
            let resultQuintoGanhoPerca = (detalhe.valor5) - (detalhe.valor4);
            setListaQuintoGanhoPerca(resultQuintoGanhoPerca);
            setListaQuintoGanhoPercaColor('text-success');
          }
        }
        
        if (detalhe.valor6 !== null) {
        
          setListaSexto(true);
          setContadorLista(7);
        
          if (convertToInteger(detalhe.valor5) >= convertToInteger(detalhe.valor6)) {
            let resultSextoGanhoPerca = (detalhe.valor5) - (detalhe.valor6);
            setListaSextoGanhoPerca(resultSextoGanhoPerca);
            setListaSextoGanhoPercaColor('text-danger');
          
          } else {
            let resultSextoGanhoPerca = (detalhe.valor6) - (detalhe.valor5);
            setListaSextoGanhoPerca(resultSextoGanhoPerca);
            setListaSextoGanhoPercaColor('text-success');
          }
        }
        
        if (detalhe.valor7 !== null) {
        
          setListaSetimo(true);
          setContadorLista(8);
        
          if (convertToInteger(detalhe.valor6) >= convertToInteger(detalhe.valor7)) {
            let resultSetimoGanhoPerca = (detalhe.valor6) - (detalhe.valor7);
            setListaSetimoGanhoPerca(resultSetimoGanhoPerca);
            setListaSetimoGanhoPercaColor('text-danger');
          
          } else {
            let resultSetimoGanhoPerca = (detalhe.valor7) - (detalhe.valor6);
            setListaSetimoGanhoPerca(resultSetimoGanhoPerca);
            setListaSetimoGanhoPercaColor('text-success');
          }
        }
        
        if (detalhe.valor8 !== null) {
        
          setListaOitavo(true);
          setContadorLista(9);
        
          if (convertToInteger(detalhe.valor7) >= convertToInteger(detalhe.valor8)) {
            let resultOitavoGanhoPerca = (detalhe.valor7) - (detalhe.valor8);
            setListaOitavoGanhoPerca(resultOitavoGanhoPerca);
            setListaOitavoGanhoPercaColor('text-danger');
          
          } else {
            let resultOitavoGanhoPerca = (detalhe.valor8) - (detalhe.valor7);
            setListaOitavoGanhoPerca(resultOitavoGanhoPerca);
            setListaOitavoGanhoPercaColor('text-success');
          }
        }
        
        if (detalhe.valor9 !== null) {
        
          setListaNono(true);
          setContadorLista(10);
        
          if (convertToInteger(detalhe.valor8) >= convertToInteger(detalhe.valor9)) {
            let resultNonoGanhoPerca = (detalhe.valor8) - (detalhe.valor9);
            setListaNonoGanhoPerca(resultNonoGanhoPerca);
            setListaNonoGanhoPercaColor('text-danger');
          
          } else {
            let resultNonoGanhoPerca = (detalhe.valor9) - (detalhe.valor8);
            setListaNonoGanhoPerca(resultNonoGanhoPerca);
            setListaNonoGanhoPercaColor('text-success');
          }
        }
        
        if (detalhe.valor10 !== null) {
        
          setListaDecimo(true);
          setContadorLista(11);
        
          if (convertToInteger(detalhe.valor9) >= convertToInteger(detalhe.valor10)) {
            let resultDecimoGanhoPerca = (detalhe.valor9) - (detalhe.valor10);
            setListaDecimoGanhoPerca(resultDecimoGanhoPerca);
            setListaDecimoGanhoPercaColor('text-danger');
          
          } else {
            let resultDecimoGanhoPerca = (detalhe.valor10) - (detalhe.valor9);
            setListaDecimoGanhoPerca(resultDecimoGanhoPerca);
            setListaDecimoGanhoPercaColor('text-success');
          }
        }        

        setIsEditingMaquina(true);
        setBtEditaMaquina(true);
      }
    } catch (error) {
      console.error('Erro ao obter detalhes da maquina:', error);
    }
  }
  
  useEffect(() => {
  }, [newMaquina]);

  const detalheTalhao = async (id: any) => {
    try {
      const detalhe = await getTalhaoById(id);
      
      if (detalhe) {
        
        const formattedArea = detalhe.area 
        ? parseFloat(detalhe.area).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
        : detalhe.area;
        
        setNewTalhao({
          id: detalhe.id,
          idFazenda: detalhe.idFazenda,
          nome: detalhe.nome,
          area: formattedArea,
        });
        
        setIsEditingTalhao(true);
        setBtEditaTalhao(true);
      }
    } catch (error) {
      console.error('Erro ao obter detalhes do talhão:', error);
    }
  };

  const detalheProducao = async (id: any) => {
    try {
      const detalhe = await getProducaoById(id);
      
      if (detalhe) {
        
        setNewProducao({
          id: detalhe.id,
          produto: detalhe.produto,
          subProduto: detalhe.subProduto,
          armazenamento: detalhe.armazenamento,
          unidade: detalhe.unidade,
          dataPlantio: detalhe.dataPlantio,
          produtividade: detalhe.produtividade,
          valorUnitario: detalhe.valorUnitario,
          dataColheita: detalhe.dataColheita,
          variedadeProduto: detalhe.variedadeProduto,
          cicloEstimado: detalhe.cicloEstimado,
          dataColheitaEstimada: detalhe.dataColheitaEstimada,
          valorTotal: detalhe.valorTotal,
          safra: detalhe.safra,
          talhao: detalhe.talhao,
          idFazenda: detalhe.idFazenda
        });
        
        setIsEditingProducao(true);
        setBtEditaProducao(true);
      }
    } catch (error) {
      console.error('Erro ao obter detalhes do talhão:', error);
    }
  };
  
  const detalheInscricao = async (id: any) => {
    try {
      const detalhe = await getInscricaoEstadualById(id);
      
      if (detalhe) {
        
        setNewInscricaoEstadual({
          id: detalhe.id,
          idFazenda: detalhe.idFazenda,
          uf: detalhe.uf,
          cep: detalhe.cep,
          endereco: detalhe.endereco,
          bairro: detalhe.bairro,
          cidade: detalhe.cidade,
          complemento: detalhe.complemento,
          numero: detalhe.numero,
          matricula: detalhe.matricula,
          inscricao_estadual: detalhe.inscricao_estadual
        });
        
        setIsEditingInscricao(true);
        setBtEditaInscricao(true);
      }
    } catch (error) {
      console.error('Erro ao obter detalhes do talhão:', error);
    }
  };
  
  const fetchInscricoesEstaduais = async (id: any, search = '') => {
    try {
      const inscricoes = await getInscricoesEstaduais(id, search);
      const fazendas = await getFazendas();
      const idUser = Cookies.get('TokenBrasao');
      const filteredFazendas = fazendas.filter((fazenda: Fazenda) => fazenda.idUser == idUser);
      const filteredFazendaIds = filteredFazendas.map((fazenda: Fazenda) => fazenda.id);
      const filteredInscricoes = inscricoes.filter((inscricao: InscricaoEstadual) => 
        filteredFazendaIds.includes(inscricao.idFazenda)
    );
    const inscricoesComNomeFazenda = filteredInscricoes.map((inscricao: InscricaoEstadual) => {
      const fazenda = fazendas.find((f: Fazenda) => f.id === inscricao.idFazenda);
      return { ...inscricao, fazendaNome: fazenda ? fazenda.nome : 'N/A' };
    });
    setInscricoes(inscricoesComNomeFazenda);
    setFazendas(filteredFazendas);
  } catch (error) {
    console.error('Error fetching inscrições estaduais:', error);
  }
};

const fetchTalhoes = async (id: any, search = '') => {
  try {
    const talhoes = await getTalhoes(id, search);
    const fazendas = await getFazendas();
    const idUser = Cookies.get('TokenBrasao');
    const filteredFazendas = fazendas.filter((fazenda: Fazenda) => fazenda.idUser == idUser);
    const filteredFazendaIds = filteredFazendas.map((fazenda: Fazenda) => fazenda.id);
    const filteredTalhoes = talhoes.filter((talhao: Talhao) => 
      filteredFazendaIds.includes(talhao.idFazenda)
  );
  const talhoesComNomeFazenda = filteredTalhoes.map((talhao: Talhao) => {
    const fazenda = fazendas.find((f: Fazenda) => f.id === talhao.idFazenda);
    return { ...talhao, fazendaNome: fazenda ? fazenda.nome : 'N/A' };
  });
  setTalhoes(talhoesComNomeFazenda);
  setFazendas(filteredFazendas);
  
  const totalArea = talhoes.reduce((total:any, talhao:any) => {
    const area = convertToInteger(talhao.area);
    return total + area;
  }, 0);
  
  setSomatorioAreasTalhoes(totalArea);
  
} catch (error) {
  console.error('Error fetching inscrições estaduais:', error);
}
};

const fetchMaquinas = async (id: any, search = '') => {
  try {
    const maquinas = await getMaquinas(id, search);
    const fazendas = await getFazendas();
    const idUser = Cookies.get('TokenBrasao');
    const filteredFazendas = fazendas.filter((fazenda: Fazenda) => fazenda.idUser == idUser);
    const filteredFazendaIds = filteredFazendas.map((fazenda: Fazenda) => fazenda.id);
    const filteredMaquinas = maquinas.filter((maquina: Maquina) => 
      filteredFazendaIds.includes(maquina.idFazenda)
  );
  const maquinasComNomeFazenda = filteredMaquinas.map((maquina: Maquina) => {
    const fazenda = fazendas.find((f: Fazenda) => f.id === maquina.idFazenda);
    return { ...maquina, fazendaNome: fazenda ? fazenda.nome : 'N/A' };
  });
  setMaquinas(maquinasComNomeFazenda);
  setFazendas(filteredFazendas);
} catch (error) {
  console.error('Error fetching inscrições estaduais:', error);
}
};

const fetchProducoes = async (id: any, search = '') => {
  try {
  
    const producoes = await getProducoes(id, search);

    console.log(producoes);

    const fazendas = await getFazendas();

    const idUser = Cookies.get('TokenBrasao');

    const filteredFazendas = fazendas.filter((fazenda: Fazenda) => fazenda.idUser == idUser);

    const filteredFazendaIds = filteredFazendas.map((fazenda: Fazenda) => fazenda.id);

    const filteredProducoes = producoes.filter((producao: ProducaoState) => 
      filteredFazendaIds.includes(producao.talhao) 
    );

    const producoesComNomeFazenda = filteredProducoes.map((producao: ProducaoState) => {
      
      const fazenda = fazendas.find((f: Fazenda) => f.id === Number(producao.talhao));

      return { ...producao, fazendaNome: fazenda ? fazenda.nome : 'N/A' };
    });

    console.log(producoesComNomeFazenda);

    setProducoes(producoes);
    setFazendas(filteredFazendas);
    
  } catch (error) {
    console.error('Error fetching produções:', error);
  }
};

const handleFazenda = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  const { name, value } = e.target;
  
  setNewFazenda({ ...newFazenda, [name]: value });
};

const convertToInteger = (value: string) => {
  
  if(value == ''){

    value = '0';
  }

  value = value.replace('.', '');
  value = value.replace('.', '');
  value = value.replace('.', '');
  value = value.replace('.', '');
  value = value.replace('.', '');
  value = value.replace(',', '.');
  
  return parseFloat(value);
}

const handleCep = (e: any) => {
  let cleanedValue = e.target.value.replace(/\D/g, ''); 
  let maskedValue = cleanedValue.replace(/(\d{5})(\d)/, '$1-$2');
  setNewInscricaoEstadual({ ...newInscricaoEstadual, ['cep']: maskedValue });
}

const handlePlaca = (e: any) => {
  let cleanedValue = e.target.value.replace(/\W/g, '').toUpperCase(); 
  let maskedValue = cleanedValue.replace(/(\w{3})(\w)/, '$1-$2');
  setNewMaquina({ ...newMaquina, ['placa']: maskedValue });
}

const applyMask = (value: string, uf: string) => {
  let cleanedValue = value.replace(/\D/g, '');
  switch (uf) {
    case 'AC': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1,3})?(\d{1,2})?/, (_, p1, p2, p3, p4, p5) => 
      [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `/${p4}`, p5 && `-${p5}`].filter(Boolean).join(''));
    case 'AL': return cleanedValue.replace(/(\d{1,9})/, '$1');
    case 'AM': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/, (_, p1, p2, p3, p4) => 
      [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join(''));
    case 'AP': return cleanedValue.replace(/(\d{1,9})/, '$1');
    case 'BA': return cleanedValue.replace(/(\d{1,6})(\d{1,2})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'CE': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'DF': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d{1,2})?/, (_, p1, p2, p3, p4, p5) => 
      [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `/${p4}`, p5 && `-${p5}`].filter(Boolean).join(''));
    case 'ES': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'GO': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/, (_, p1, p2, p3, p4) => 
      [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join(''));
    case 'MA': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'MG': return cleanedValue.replace(/(\d{1,7})?(\d{1,2})?(\d{1,2})?/, (_, p1, p2, p3) => 
      [p1, p2 && `.${p2}`, p3 && `-${p3}`].filter(Boolean).join(''));
    case 'MS': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'MT': return cleanedValue.replace(/(\d{1,10})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'PA': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/, (_, p1, p2, p3, p4) => 
      [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join(''));
    case 'PB': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'PE': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'PI': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'PR': return cleanedValue.replace(/(\d{1,3})(\d{1,5})?(\d{1,2})?/, (_, p1, p2, p3) => 
      [p1, p2 && `.${p2}`, p3 && `-${p3}`].filter(Boolean).join(''));
    case 'RJ': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,2})?(\d{1})?/, (_, p1, p2, p3, p4) => 
      [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join(''));
    case 'RN': return cleanedValue.replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/, (_, p1, p2, p3, p4) => 
      [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join(''));
    case 'RO': return cleanedValue.replace(/(\d{1,13})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'RR': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'RS': return cleanedValue.replace(/(\d{1,3})(\d{1,7})?/, (_, p1, p2) => 
      [p1, p2 && `/${p2}`].filter(Boolean).join(''));
    case 'SC': return cleanedValue.replace(/(\d{1,3})(\d{1,3})?(\d{1,3})?/, (_, p1, p2, p3) => 
      [p1, p2 && `.${p2}`, p3 && `.${p3}`].filter(Boolean).join(''));
    case 'SE': return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    case 'SP': return cleanedValue.replace(/(\d{1,3})(\d{1,3})?(\d{1,3})?(\d{1,3})?/, (_, p1, p2, p3, p4) => 
      [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `.${p4}`].filter(Boolean).join(''));
    case 'TO': return cleanedValue.replace(/(\d{1,10})(\d{1})?/, (_, p1, p2) => 
      [p1, p2 && `-${p2}`].filter(Boolean).join(''));
    default: return value;
  }
};

const handleInscricaoEstadual = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  const { name, value } = e.target;
  
  if (name === 'cep') {
    if (value.length === 9) {
      fetchAddress(value);
    }
  }
  
  if (name === 'uf') {
    newInscricaoEstadual.cidade = '';
    newInscricaoEstadual.cep = '';
    newInscricaoEstadual.inscricao_estadual = '';
    newInscricaoEstadual.endereco = '';
    newInscricaoEstadual.numero = '';
    newInscricaoEstadual.complemento = '';
    newInscricaoEstadual.bairro = '';
    
    setNewInscricaoEstadual(prevState => ({
      ...prevState,
      inscricao_estadual: '',
      [name]: value
    }));
    
    if (value === '') {
      setDisabledInscricao(true);
      setInscricaoPlaceholder("Selecione o Estado");
    } else {
      setDisabledInscricao(false);
      
      const placeholders: { [key: string]: { placeholder: string, maxlength: number } } = {
        'AC': { placeholder: "__/____.____/___-__", maxlength: 18 },
        'AL': { placeholder: "_________", maxlength: 9 },
        'AM': { placeholder: "___.___.___-_", maxlength: 12 },
        'AP': { placeholder: "_________", maxlength: 9 },
        'BA': { placeholder: "______-__", maxlength: 9 },
        'CE': { placeholder: "________-_", maxlength: 10 },
        'DF': { placeholder: "__.___.___/___-__", maxlength: 15 },
        'ES': { placeholder: "________-_", maxlength: 10 },
        'GO': { placeholder: "__.____.____-_", maxlength: 12 },
        'MA': { placeholder: "________-_", maxlength: 10 },
        'MG': { placeholder: "_______.__-__", maxlength: 13 },
        'MS': { placeholder: "________-_", maxlength: 10 },
        'MT': { placeholder: "__________-_", maxlength: 12 },
        'PA': { placeholder: "__.___.___-_", maxlength: 12 },
        'PB': { placeholder: "________-_", maxlength: 10 },
        'PE': { placeholder: "________-_", maxlength: 10 },
        'PI': { placeholder: "________-_", maxlength: 10 },
        'PR': { placeholder: "___._____-__", maxlength: 11 },
        'RJ': { placeholder: "__.___.__-_", maxlength: 10 },
        'RN': { placeholder: "__.___.___-_", maxlength: 12 },
        'RO': { placeholder: "_____________-_", maxlength: 15 },
        'RR': { placeholder: "________-_", maxlength: 10 },
        'RS': { placeholder: "___/_______", maxlength: 11 },
        'SC': { placeholder: "___.___.___", maxlength: 11 },
        'SE': { placeholder: "________-_", maxlength: 10 },
        'SP': { placeholder: "___.___.___.___", maxlength: 15 },
        'TO': { placeholder: "__________-_", maxlength: 12 }
      };
      
      const { placeholder, maxlength } = placeholders[value] || { placeholder: "Selecione o Estado", maxlength: 100 };
      
      setInscricaoPlaceholder(placeholder);
      setUfComboSel(value);
      setNewInscricaoEstadual(prevState => ({
        ...prevState,
        inscricao_estadual: '',
        maxlength
      }));
    }
  } else {
    if (name == 'cep' && value.length === 9) {
      applyMask(value, ufComboSel);
    }
    
    const maskedValue = name === 'inscricao_estadual' ? applyMask(value, ufComboSel) : value;
    
    setNewInscricaoEstadual(prevState => ({
      ...prevState,
      [name]: maskedValue
    }));
  }
};

const handleDecimal4 = (e: any) => {
  let inputValue = e.target.value;
  setNewTalhao({ ...newTalhao, ['area']: inputValue });
};

const handleTalhao = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  const { name, value } = e.target;
  
  setNewTalhao(prevState => ({
    ...prevState,
    [name]: value
  }));
};  

const handleProducao = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  
  const { name, value } = e.target;

  if (name === 'unidade') {
    setUnidadeProducaoSel('(' + value + ')');
  }

  if (name === 'talhao') {
    
    const talhaoSelecionado = talhoes.find(talhao => talhao.id === parseInt(value));

    if (talhaoSelecionado) {

      const talhaoArea = convertToInteger(talhaoSelecionado.area);  

      setNewProducao(prevState => ({
        ...prevState,
        talhao: value, 
        area: talhaoArea 
      }));

      setTalhaoAreaSelecionado(talhaoArea);
    }
  }

  if (name === 'produtividade') {

    setNewProducao(prevState => ({
      ...prevState,
      [name]: Number(value)
    }));
  }else{

    setNewProducao(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
};

useEffect(() => {
  
  if (newProducao.produtividade && talhaoAreaSelecionado !== null) {

    const valorTotal = Number(newProducao.produtividade) * talhaoAreaSelecionado;

    setNewProducao(prevState => ({
      ...prevState,
      valorTotal: valorTotal.toFixed(2)
    }));
  }
}, [talhaoAreaSelecionado, newProducao.produtividade]);  

const handleMaquina = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  const { name, value } = e.target;

  if (name === 'ano_aquisicao' && value.length === 4) {
    const ano1 = convertToInteger(value);
    
    const anos = Array.from({ length: 10 }, (_, i) => (ano1 + i).toString());

    setNewMaquina((prevState) => ({
      ...prevState,
      ano1: anos[0],
      ano2: anos[1],
      ano3: anos[2],
      ano4: anos[3],
      ano5: anos[4],
      ano6: anos[5],
      ano7: anos[6],
      ano8: anos[7],
      ano9: anos[8],
      ano10: anos[9],
      [name]: value,
    }));
  } else {
    setNewMaquina((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }
};

const handleSubmit = async () => {

  setAlerta('');
  
  await new Promise(resolve => setTimeout(resolve, 0));
  
  if (!newFazenda.nome.trim()) {
    setAlerta('Preencha o nome da fazenda.');
    return;
  }
  
  if (newFazenda.nome.trim().length < 3) {
    setAlerta('O nome deve ter pelo menos 3 caracteres.');
    return;
  }
  
  try {

    let result: any;
    
    const areaTotalHectare = convertToInteger(newFazenda.area_total_hectare);
    const areaPreservacaoPermanente = convertToInteger(newFazenda.area_preservacao_permanente);
    const areaReservaAmbiental = convertToInteger(newFazenda.area_reserva_ambiental);
    const areaUtil = convertToInteger(newFazenda.area_util);
    
    if(areaTotalHectare < (areaPreservacaoPermanente + areaReservaAmbiental + areaUtil)){
      
      setAlerta('A área total não deve ser menor do que o somatório das demais áreas.');
      
      setTimeout(() => {
        setAlerta('');
      }, 0);
    }
    
    if(areaTotalHectare >= (areaPreservacaoPermanente + areaReservaAmbiental + areaUtil)){
      
      if (isEditing) {

        result = await updateFazenda(newFazenda);
      } else {
        
        result = await createFazenda(newFazenda);
      }
      
      if (result.error) {

        setAlerta(result.error);
      } else {

        navigate("/fazenda/"+result.id);

        /* if (isEditing) {

          setFazendas(fazendas.map(fazenda => (fazenda.id === newFazenda.id ? result : fazenda)));
        } else {
          
          setFazendas([...fazendas, result]);
          handleClose();
          edit(result.id);
        } */

        setAlerta(isEditing ? 'Fazenda atualizada com sucesso.' : 'Fazenda cadastrada com sucesso.');
      }
    }
  } catch (error) {
    setAlerta('Não foi possível realizar esta operação.');
  }
};

const [municipios, setMunicipios] = useState<Municipio[]>([]);
const [filteredMunicipios, setFilteredMunicipios] = useState<Municipio[]>([]);

useEffect(() => {
  const fetchMunicipios = async () => {
    const response = await fetch('/municipios.json');
    const data: Municipio[] = await response.json();
    setMunicipios(data);
  };
  
  fetchMunicipios();
}, []);

useEffect(() => {
  if (newInscricaoEstadual.uf) {
    setFilteredMunicipios(municipios.filter(municipio => municipio.estado === newInscricaoEstadual.uf));
  } else {
    setFilteredMunicipios([]);
  }
}, [newInscricaoEstadual.uf, municipios]);

const style = document.createElement("style");
style.innerHTML = `
    .modal-dialog{
      min-width: 90%;
    }
  `;
document.head.appendChild(style);

useEffect(() => {
  fetchFazendas();
}, []);

useEffect(() => {
  if (isEditing) {
    
    fetchInscricoesEstaduais(idFazendaSel);
    fetchTalhoes(idFazendaSel);
    fetchMaquinas(idFazendaSel);
  }
}, [isEditing, idFazendaSel]); 

useEffect(() => {
  if (newProducao.dataPlantio && newProducao.cicloEstimado) {

    const dataPlantio = new Date(newProducao.dataPlantio);
    const ciclo = parseInt(newProducao.cicloEstimado, 10);

    if (!isNaN(ciclo)) {
      dataPlantio.setDate(dataPlantio.getDate() + ciclo);
      const dataColheitaEstimada = dataPlantio.toISOString().split('T')[0];
      setNewProducao((prevState) => ({
        ...prevState,
        dataColheitaEstimada,
      }));
    }
  }
}, [newProducao.dataPlantio, newProducao.cicloEstimado]);

return (
  <>
  <HeaderComponent />
  <div className='page-content'>
  <MenuComponent />
  <div className='content-wrapper'>
  <div className='content-inner'>
  <div className="page-header page-header-light">
  <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
  <div className="d-flex">
  <div className="breadcrumb">
  <a onClick={() => navigate('/')} className="breadcrumb-item">
  <IoMdHome /> &nbsp; Início
  </a>
  <span className="breadcrumb-item active">Fazendas</span>
  </div>
  <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more"></i></a>
  </div>
  </div>
  </div>
  <div className='content'>
  <div className='card'>
  <div className="card-header">
  <h5 className="card-title d-flex float-left">
  <Button className='bt-1-ico' variant="indigo" onClick={add}><IoAddCircleOutline className='m-0-auto fs-2em' /></Button>
  <span className='fs-1-5em pl-2'>Fazendas</span>
  </h5>
  <div className='float-right'>
  <form onSubmit={handleSearchSubmit}>
  <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
  <input
  type="text"
  className="form-control form-control-lg"
  placeholder="Pesquisar por nome..."
  value={search}
  onChange={handleSearchChange}
  />
  <div className="form-control-feedback form-control-feedback-lg pt-0">
  <button type="submit" className="btn btn-link p-0">
  <IoSearch className='fs-1-7em' />
  </button>
  </div>
  </div>
  </form>
  </div>
  </div>
  <div className='card-body'>
  <div className="table-responsive">
  <table className="table table-bordered table-striped">
  <thead>
  <tr>
  <th style={{ width: '80px' }}>#</th>
  <th>Nome</th>
  </tr>
  </thead>
  <tbody>
  {fazendas.map(fazenda => (
    <tr key={fazenda.id} onClick={() => edit(fazenda.id)} className='cursor-pointer'>
    <td>{fazenda.id}</td>
    <td>{fazenda.nome}</td>
    </tr>
  ))}
  </tbody>
  </table>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  <Modal show={show} onHide={handleClose}>
  <Modal.Header>
  <Modal.Title></Modal.Title>
  </Modal.Header>
  <Modal.Body>
  
  
  <Tabs
  defaultActiveKey="home"
  id="justify-tab-example"
  className="mb-3"
  >
  <Tab eventKey="home" title="Fazenda">
  <Row>
  <Col lg={4}>
  <div className="form-group">
  <label>Nome: </label>
  <input type="text" className="form-control" placeholder="Nome" name="nome" value={newFazenda.nome} onChange={handleFazenda} />
  </div>
  </Col>
  <Col lg={2}>
  <div className="form-group">
  <label>Valor R$: <small>(hectare)</small> </label>
  <InputDecimal2Component placeholder="0,00" value={newFazenda.valor_hectare} name="valor_hectare" onChange={handleFazenda} min="0" step="0,01" />
  </div>            
  </Col>
  <Col lg={2}>
  <div className="form-group">
  <label>Área total: <small>(hectare)</small> </label>
  <InputDecimal2Component placeholder="0,00" value={newFazenda.area_total_hectare} name="area_total_hectare" onChange={handleFazenda} min="0" step="0,01" />
  </div>            
  </Col>  
  <Col lg={3}>
  <div className="form-group">
  <label>Área Preservação Permanente: <small>(hectares)</small> </label>
  <InputDecimal2Component placeholder="0,00" value={newFazenda.area_preservacao_permanente} name="area_preservacao_permanente" onChange={handleFazenda} min="0" step="0,01" />
  </div>            
  </Col>  
  <Col lg={3}>
  <div className="form-group">
  <label>Área Reserva Ambiental: <small>(hectares)</small> </label>
  <InputDecimal2Component placeholder="0,00" value={newFazenda.area_reserva_ambiental} name="area_reserva_ambiental" onChange={handleFazenda} min="0" step="0,01" />
  </div>            
  </Col>  
  <Col lg={2}>
  <div className="form-group">
  <label>Área Útil: <small>(hectares)</small> </label>
  <InputDecimal2Component placeholder="0,01" value={newFazenda.area_util} name="area_util" onChange={handleFazenda} min="0" step="0,01" />
  </div>            
  </Col>  
  <Col lg={2}>
  <div className="form-group">
  <label>Status: </label>
  <select className='form-control' name="status" value={newFazenda.status} onChange={handleFazenda}>
  <option value="1">Ativo</option>
  <option value="0">Inativo</option>
  </select>
  </div>
  </Col>
  <Col lg={2}>
  <div className="form-group">
  <label>Fazenda arrendada: </label>
  <select className='form-control' name="arrendada" value={newFazenda.arrendada} onChange={handleFazenda}>
  <option value="0">Não</option>
  <option value="1">Sim</option>
  </select>
  </div>
  </Col>
  <Col lg={2}>
  <div className="form-group mt-4">
  <Button variant="primary" onClick={handleSubmit} className='btn-block'>
  {isEditing ? 'Atualizar' : 'Salvar'}
  </Button>
  </div>
  </Col>
  </Row>
  </Tab>
  <Tab eventKey="profile" title="Inscrições Estaduais">
  {!isEditing && (
    <h5>Para habilitar esta aba conclua o cadastro inicial da fazenda.</h5>
  )}
  
  {isEditing && (
    <>
    <h4>Cadastrar Inscrição Estadual</h4>
    <Row>
    <Col lg={2}>
    <div className="form-group">
    <label>Estado: </label>
    <select className='form-control' name="uf" value={newInscricaoEstadual.uf} onChange={handleInscricaoEstadual}>
    <option value="">-- selecione --</option>
    <option value="AC">Acre</option>
    <option value="AL">Alagoas</option>
    <option value="AP">Amapá</option>
    <option value="AM">Amazonas</option>
    <option value="BA">Bahia</option>
    <option value="CE">Ceará</option>
    <option value="DF">Distrito Federal</option>
    <option value="ES">Espírito Santo</option>
    <option value="GO">Goiás</option>
    <option value="MA">Maranhão</option>
    <option value="MT">Mato Grosso</option>
    <option value="MS">Mato Grosso do Sul</option>
    <option value="MG">Minas Gerais</option>
    <option value="PA">Pará</option>
    <option value="PB">Paraíba</option>
    <option value="PR">Paraná</option>
    <option value="PE">Pernambuco</option>
    <option value="PI">Piauí</option>
    <option value="RJ">Rio de Janeiro</option>
    <option value="RN">Rio Grande do Norte</option>
    <option value="RS">Rio Grande do Sul</option>
    <option value="RO">Rondônia</option>
    <option value="RR">Roraima</option>
    <option value="SC">Santa Catarina</option>
    <option value="SP">São Paulo</option>
    <option value="SE">Sergipe</option>
    <option value="TO">Tocantins</option>
    </select>
    </div>
    </Col>
    <Col lg={2}>
    <div className="form-group">
    <label>CEP: </label>
    <input type="text" className="form-control" value={newInscricaoEstadual.cep} placeholder="_____-___" name="cep" onKeyUp={handleCep} onChange={handleInscricaoEstadual} />
    </div>            
    </Col>
    <Col lg={3}>
    <div className="form-group">
    <label>Cidade: </label>
    <select
    className="form-control"
    value={newInscricaoEstadual.cidade}
    name="cidade"
    onChange={handleInscricaoEstadual}
    disabled={filteredMunicipios.length === 0}
    >
    <option value="">Selecione a Cidade</option>
    {filteredMunicipios.map(municipio => (
      <option key={municipio.cod_municipio} value={municipio.nome_municipio}>
      {municipio.nome_municipio}
      </option>
    ))}
    </select>
    </div>                         
    </Col>    
    <Col lg={2}>
    <div className="form-group">
    <label>Inscrição estadual: </label>
    <input
    type="text"
    className="form-control"
    value={newInscricaoEstadual.inscricao_estadual}
    onChange={handleInscricaoEstadual}
    placeholder={inscricaoPlaceholder}
    name="inscricao_estadual"
    disabled={disabledInscricao}
    maxLength={newInscricaoEstadual.maxlength}
    />
    </div>
    </Col>
    <Col lg={3}>
    <div className="form-group">
    <label>Endereço: </label>
    <input type="text" className="form-control" value={newInscricaoEstadual.endereco} placeholder='Endereço' name="endereco" onChange={handleInscricaoEstadual} />
    </div>            
    </Col>
    <Col lg={1}>
    <div className="form-group">
    <label>Número: </label>
    <input type="text" className="form-control" value={newInscricaoEstadual.numero} name="numero" onChange={handleInscricaoEstadual} />
    </div>            
    </Col>
    <Col lg={2}>
    <div className="form-group">
    <label>Complemento: </label>
    <input type="text" className="form-control" value={newInscricaoEstadual.complemento} name="complemento" placeholder='Complemento' onChange={handleInscricaoEstadual} />
    </div>            
    </Col>
    <Col lg={3}>
    <div className="form-group">
    <label>Bairro: </label>
    <input type="text" className="form-control" value={newInscricaoEstadual.bairro} name="bairro" placeholder='Bairro' onChange={handleInscricaoEstadual} />
    </div>                         
    </Col>
    
    <Col lg={3}>
    <div className="form-group">
    <label>Matrícula: </label>
    <input type="text" className="form-control" value={newInscricaoEstadual.matricula} name="matricula" placeholder='Matrícula' onChange={handleInscricaoEstadual} />
    </div>                         
    </Col>
    <Col lg={2} className='mt-4'>
    
    {
      btEditaInscricao ? (
        <>
        <Button variant="primary" onClick={editaInscricaoEstadual}>
        Editar
        </Button>
        <Button variant="light" onClick={cancelaInscricaoEstadual} className='ml-2'>
        Cancelar
        </Button>
        </>
      ) : (
        <Button variant="primary" onClick={addInscricaoEstadual}>
        Adicionar
        </Button>
      )
    } 
    
    </Col>
    </Row>
    <h4>Lista de Inscrições Estaduais</h4>
    <table className="table table-bordered table-striped mt-3">
    <thead>
    <tr>
    <th style={{ width: '80px' }}>#</th>
    <th>UF</th>
    <th>Inscrição Estadual</th>
    <th>Endereço</th>
    <th style={{width:'100px'}}></th>
    </tr>
    </thead>
    <tbody>
    {inscricoes.length === 0 ? (
      <tr>
      <td colSpan={5} className='text-center'>Nenhum registro</td>
      </tr>
    ) : (
      inscricoes.map(inscricao => (
        <tr key={inscricao.id} onClick={() => edit(inscricao.id)} className='cursor-pointer'>
        <td>{inscricao.id}</td>
        <td>{inscricao.uf}</td>
        <td>{inscricao.inscricao_estadual}</td>
        <td>{inscricao.endereco} {inscricao.numero} {inscricao.complemento} {inscricao.bairro} {inscricao.cidade} {inscricao.cep}</td>
        <td style={{width:'235px'}}>
        <Button variant="secondary" onClick={() => detalheInscricao(inscricao.id)} className='float-start btn-sm mr-3'>
        Editar
        </Button>
        <Button variant="danger" onClick={() => removeInscricao(inscricao.id)} className='float-start btn-sm'>
        Remover
        </Button>    
        </td>
        </tr>
      ))
    )}
    </tbody>
    </table> 
    </>
  )}
  </Tab>
  <Tab eventKey="longer-tab" title="Talhões">
  
  {!isEditing && (
    <h5>Para habilitar esta aba conclua o cadastro inicial da fazenda.</h5>
  )}  
  
  {isEditing && (
    <>
    <h4>Cadastrar Talhão 
    &nbsp; <span className='badge bg-dark text-white px-3 py-2'>Área útil total da fazenda: {newFazenda.area_util} hectare(s)</span>
    &nbsp;&nbsp; <span className='badge bg-dark text-white px-3 py-2'>Somatório dos talhões: <small>(áreas)</small>: {somatorioAreasTalhoes} hectare(s)</span>
    </h4>
    <Row>
    <Col lg={3}>
    <div className="form-group">
    <label>Nome: </label>
    <input type="text" className="form-control" value={newTalhao.nome} placeholder='Nome' name="nome" onChange={handleTalhao} />
    </div>            
    </Col>
    <Col lg={2}>
    <div className="form-group">
    <label>Área: <small>(hectares)</small> </label>
    <InputDecimal2Component placeholder="0,00" value={newTalhao.area} name="area" onChange={handleDecimal4} min="0" step="0,01" />
    </div>            
    </Col>
    <Col lg={2} className='mt-4'>
    
    {
      btEditaTalhao ? (
        <>
        <Button variant="primary" onClick={editaTalhao}>
        Editar
        </Button>
        <Button variant="light" onClick={cancelaTalhao} className='ml-2'>
        Cancelar
        </Button>
        </>
      ) : (
        <Button variant="primary" onClick={addTalhao}>
        Adicionar
        </Button>
      )
    }   
    
    </Col>
    </Row>
    <h4>Lista de Talhões</h4>
    <table className="table table-bordered table-striped mt-3">
    <thead>
    <tr>
    <th style={{ width: '80px' }}>#</th>
    <th>Nome</th>
    <th>Área <small>(hectares)</small></th>
    <th></th>
    </tr>
    </thead>
    <tbody>
    {talhoes.length === 0 ? (
      <tr>
      <td colSpan={5} className='text-center'>Nenhum registro</td>
      </tr>
    ) : (
      talhoes.map(talhao => (
        <tr key={talhao.id} onClick={() => edit(talhao.id)} className='cursor-pointer'>
        <td>{talhao.id}</td>
        <td>{talhao.nome}</td>
        <td>{talhao.area}</td>
        <td style={{width:"235px"}}> 
        <Button variant="secondary" onClick={() => detalheTalhao(talhao.id)} className='float-start btn-sm mr-3'>
        Editar
        </Button>    
        <Button variant="danger" onClick={() => removeTalhao(talhao.id)} className='float-start btn-sm'>
        Remover
        </Button>    
        </td>
        </tr>
      ))
    )}
    </tbody>
    </table> 
    </>
  )}      
  </Tab>
  
  
  <Tab eventKey="maquinas" title="Máquinas/Benfeitorias">
  
  {!isEditing && (
    <h5>Para habilitar esta aba conclua o cadastro inicial da fazenda.</h5>
  )}  
  
  {isEditing && (
    <>
    <h4>Cadastrar Máquina/Benfeitoria</h4>
    <Row>
    
    <Col xs={7}>
    <Row>
    <Col lg={6}>
    <div className="form-group">
    <label>Descrição: </label>
    <input type="text" className="form-control" value={newMaquina.descricao} placeholder='Descrição' name="descricao" onChange={handleMaquina} />
    </div>            
    </Col>
    <Col lg={3}>
    <div className="form-group">
    <label>Marca: </label>
    <input type="text" className="form-control" value={newMaquina.marca} placeholder='Marca' name="marca" onChange={handleMaquina} />
    </div>            
    </Col>
    <Col lg={3}>
    <div className="form-group">
    <label>Categoria: </label>
    <select className="form-control" name="tipo" value={newMaquina.tipo} onChange={handleMaquina}>
    <option value="">Selecione</option>
    <option value="Trator">Tratores</option>
    <option value="Implementos">Implementos</option>
    <option value="Galpoes">Galpões</option>
    <option value="Pivos">Pivôs</option>
    <option value="Construcoes">Construções</option>
    </select>
    </div>
    </Col>
    <Col lg={4}>
    <div className="form-group">
    <label>Modelo: </label>
    <input type="text" className="form-control" value={newMaquina.modelo} placeholder='Modelo' name="modelo" onChange={handleMaquina} />
    </div>            
    </Col>
    <Col lg={4}>
    <div className="form-group">
    <label>Ano: <small>(fabricação)</small> </label>
    <input type="number" max="9999" className="form-control" value={newMaquina.ano_fabricacao} placeholder='Ano' name="ano_fabricacao" onChange={(e) => { const value = e.target.value; if (value.length <= 4) { handleMaquina(e); }}}  />
    </div>            
    </Col>
    <Col lg={4}>
    <div className="form-group">
    <label>Ano: <small>(aquisição)</small> </label>
    <input type="number" max="9999" className="form-control" value={newMaquina.ano_aquisicao} placeholder='Ano' name="ano_aquisicao" onChange={(e) => { const value = e.target.value; if (value.length <= 4) { handleMaquina(e); }}} />
    </div>            
    </Col>
    <Col lg={4}>
    <div className="form-group">
    <label>Placa: </label>
    <input type="text" className="form-control" value={newMaquina.placa} name="placa" onChange={handleMaquina} placeholder="___-____" onKeyUp={handlePlaca} maxLength={8} />
    </div>            
    </Col>
    {/* <Col lg={2}>
      <div className="form-group">
      <label>Valor: <small>(aquisicao)</small> </label>
      <InputDecimal2Component placeholder="0,00" value={newMaquina.valor_aquisicao} name="valor_aquisicao" onChange={handleMaquina} min="0" step="0.01" />
      </div>
      </Col> */}
      {/*<Col lg={2}>
        <div className="form-group">
        <label>Valor: <small>(atual)</small> </label>
        <InputDecimal2Component placeholder="0,00" value={newMaquina.valor_atual} name="valor_atual" onChange={handleMaquina} min="0" step="0.01" />
        </div>            
        </Col> */}
        <Col lg={4}>
        <div className="form-group">
        <label>Vida útil: <small>(anos)</small></label>
        <input type="number" className="form-control" value={newMaquina.vida_util} placeholder='Vida Útil' name="vida_util" onChange={handleMaquina} />
        </div>            
        </Col>
        <Col lg={4}>
        <div className="form-group">
        <label>Depreciação anual: <small>(%)</small></label>
        <input type="number" className="form-control" value={newMaquina.depreciacao_anual} placeholder='Depreciação anual (%)' name="depreciacao_anual" onChange={handleMaquina} />
        </div>            
        </Col>
        {/* <Col lg={2}>
          <div className="form-group">
          <label>Valor último exercício:</label>
          <InputDecimal2Component placeholder="0,00" value={newMaquina.valor_ultimo_exercicio} name="valor_ultimo_exercicio" onChange={handleMaquina} min="0" step="0.01" />
          </div>            
          </Col> */}
          </Row>
          </Col>
          <Col lg={5} className='px-3'>
          <h6 className='text-center mb-1'>Lista de evolução patrimonial do bem</h6>
          <Table striped bordered hover>
          <thead>
          <tr>
          <th className='text-center'>Ano</th>
          <th className='text-center'>Valor</th>
          <th className='text-center d-flex'><span className='text-success text-bold'><strong>Ganho</strong></span>&nbsp;/&nbsp;<span className='text-danger'><strong>Perca</strong></span></th>
          <th>
          </th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>
          <Form.Control size="sm" type="text" disabled className='text-center' value={newMaquina.ano1} />
          </td>
          <td>
          <InputDecimal2Component value={newMaquina.valor1} onChange={(e) => handleInputChange(e, 'valor1')} disabled={!checkPrimeiroValor} />
          </td>
          <td>
          <span className='text-center cemPor d-block'> -- </span>
          </td>
          <td>
            {checkPrimeiroValor && (
              <Button variant="success" onClick={addPrimeiraLinha}><FaCheck /></Button>
            )}
            </td>
          </tr>

          {listaSegundo && (
          <tr>
            <td>
              <Form.Control size="sm" type="text" disabled className='text-center' value={newMaquina.ano2} />
            </td>
            <td>
              <InputDecimal2Component value={newMaquina.valor2} disabled={!checkSegundoValor} onChange={(e) => handleInputChange(e, 'valor2')} />
            </td>
            <td>
              <span className={`text-center cemPor d-block ${listaSegundoGanhoPercaColor}`}>{formatToBRL(listaSegundoGanhoPerca)}</span>
            </td>
            <td className='d-flex' style={{borderBottom:'none'}}>
            {checkSegundoValor && (
              <Button variant="success" onClick={addSegundaLinha}><FaCheck /></Button>
            )}
              <Button variant="danger" className='ml-2' onClick={removeSegundaLinha}><FaTrash /></Button>
              </td>
          </tr>
        )}

          {listaTerceiro && (
          <tr>
            <td>
              <Form.Control size="sm" type="text" disabled className='text-center' value={newMaquina.ano3} />
            </td>
            <td>
              <InputDecimal2Component value={newMaquina.valor3} disabled={!checkTerceiroValor} onChange={(e) => handleInputChange(e, 'valor3')} />
            </td>
            <td>
              <span className={`text-center cemPor d-block ${listaTerceiroGanhoPercaColor}`}>{formatToBRL(listaTerceiroGanhoPerca)}</span>
            </td>
            <td className='d-flex' style={{borderBottom:'none'}}>
              {checkTerceiroValor && (
                <Button variant="success" onClick={addTerceiraLinha}><FaCheck /></Button>
              )}
              <Button variant="danger" className='ml-2' onClick={removeTerceiraLinha}><FaTrash /></Button>
            </td>
          </tr>
        )}

        {listaQuarto && (
          <tr>
            <td>
              <Form.Control size="sm" type="text" disabled className='text-center' value={newMaquina.ano4} />
            </td>
            <td>
              <InputDecimal2Component value={newMaquina.valor4} disabled={!checkQuartoValor} onChange={(e) => handleInputChange(e, 'valor4')} />
            </td>
            <td>
              <span className={`text-center cemPor d-block ${listaQuartoGanhoPercaColor}`}>{formatToBRL(listaQuartoGanhoPerca)}</span>
            </td>
            <td className='d-flex' style={{borderBottom:'none'}}>
              {checkQuartoValor && (
                <Button variant="success" onClick={addQuartaLinha}><FaCheck /></Button>
              )}
              <Button variant="danger" className='ml-2' onClick={removeQuartaLinha}><FaTrash /></Button>
            </td>
          </tr>
        )}

        {listaQuinto && (
          <tr>
            <td>
              <Form.Control size="sm" type="text" disabled className='text-center' value={newMaquina.ano5} />
            </td>
            <td>
              <InputDecimal2Component value={newMaquina.valor5} disabled={!checkQuintoValor} onChange={(e) => handleInputChange(e, 'valor5')} />
            </td>
            <td>
              <span className={`text-center cemPor d-block ${listaQuintoGanhoPercaColor}`}>{formatToBRL(listaQuintoGanhoPerca)}</span>
            </td>
            <td className='d-flex' style={{borderBottom:'none'}}>
              {checkQuintoValor && (
                <Button variant="success" onClick={addQuintaLinha}><FaCheck /></Button>
              )}
              <Button variant="danger" className='ml-2' onClick={removeQuintaLinha}><FaTrash /></Button>
            </td>
          </tr>
        )}

        {listaSexto && (
          <tr>
            <td>
              <Form.Control size="sm" type="text" disabled className='text-center' value={newMaquina.ano6} />
            </td>
            <td>
              <InputDecimal2Component value={newMaquina.valor6} disabled={!checkSextoValor} onChange={(e) => handleInputChange(e, 'valor6')} />
            </td>
            <td>
              <span className={`text-center cemPor d-block ${listaSextoGanhoPercaColor}`}>{formatToBRL(listaSextoGanhoPerca)}</span>
            </td>
            <td className='d-flex' style={{borderBottom:'none'}}>
              {checkSextoValor && (
                <Button variant="success" onClick={addSextaLinha}><FaCheck /></Button>
              )}
              <Button variant="danger" className='ml-2' onClick={removeSextaLinha}><FaTrash /></Button>
            </td>
          </tr>
        )}

        {listaSetimo && (
          <tr>
            <td>
              <Form.Control size="sm" type="text" disabled className='text-center' value={newMaquina.ano7} />
            </td>
            <td>
              <InputDecimal2Component value={newMaquina.valor7} disabled={!checkSetimoValor} onChange={(e) => handleInputChange(e, 'valor7')} />
            </td>
            <td>
              <span className={`text-center cemPor d-block ${listaSetimoGanhoPercaColor}`}>{formatToBRL(listaSetimoGanhoPerca)}</span>
            </td>
            <td className='d-flex' style={{borderBottom:'none'}}>
              {checkSetimoValor && (
                <Button variant="success" onClick={addSetimaLinha}><FaCheck /></Button>
              )}
              <Button variant="danger" className='ml-2' onClick={removeSetimaLinha}><FaTrash /></Button>
            </td>
          </tr>
        )}

        {listaOitavo && (
          <tr>
            <td>
              <Form.Control size="sm" type="text" disabled className='text-center' value={newMaquina.ano8} />
            </td>
            <td>
              <InputDecimal2Component value={newMaquina.valor8} disabled={!checkOitavoValor} onChange={(e) => handleInputChange(e, 'valor8')} />
            </td>
            <td>
              <span className={`text-center cemPor d-block ${listaOitavoGanhoPercaColor}`}>{formatToBRL(listaOitavoGanhoPerca)}</span>
            </td>
            <td className='d-flex' style={{borderBottom:'none'}}>
              {checkOitavoValor && (
                <Button variant="success" onClick={addOitavaLinha}><FaCheck /></Button>
              )}
              <Button variant="danger" className='ml-2' onClick={removeOitavaLinha}><FaTrash /></Button>
            </td>
          </tr>
        )}

        {listaNono && (
          <tr>
            <td>
              <Form.Control size="sm" type="text" disabled className='text-center' value={newMaquina.ano9} />
            </td>
            <td>
              <InputDecimal2Component value={newMaquina.valor9} disabled={!checkNonoValor} onChange={(e) => handleInputChange(e, 'valor9')} />
            </td>
            <td>
              <span className={`text-center cemPor d-block ${listaNonoGanhoPercaColor}`}>{formatToBRL(listaNonoGanhoPerca)}</span>
            </td>
            <td className='d-flex' style={{borderBottom:'none'}}>
              {checkNonoValor && (
                <Button variant="success" onClick={addNonaLinha}><FaCheck /></Button>
              )}
              <Button variant="danger" className='ml-2' onClick={removeNonaLinha}><FaTrash /></Button>
            </td>
          </tr>
        )}

        {listaDecimo && (
          <tr>
            <td>
              <Form.Control size="sm" type="text" disabled className='text-center' value={newMaquina.ano10} />
            </td>
            <td>
              <InputDecimal2Component value={newMaquina.valor10} disabled={!checkDecimoValor} onChange={(e) => handleInputChange(e, 'valor10')} />
            </td>
            <td>
              <span className={`text-center cemPor d-block ${listaDecimoGanhoPercaColor}`}>{formatToBRL(listaDecimoGanhoPerca)}</span>
            </td>
            <td className='d-flex' style={{borderBottom:'none'}}>
              {checkDecimoValor && (
                <Button variant="success" onClick={addDecimaLinha}><FaCheck /></Button>
              )}
              <Button variant="danger" className='ml-2' onClick={removeDecimaLinha}><FaTrash /></Button>
            </td>
          </tr>
        )}

          {checkDecimoValor && (
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><Button variant="primary" onClick={addProximaLinha}><FaPlus /></Button></td>
          </tr>
          )}

          </tbody>
          </Table>
          </Col>
          </Row>
          <Row>
          <Col lg={12} className='mt-1 float-right text-right'>
        
          {
          btEditaMaquina ? (
          <>
            <Button variant="primary" onClick={editaMaquina}>
            Editar
            </Button>
            <Button variant="light" onClick={cancelaMaquina} className='ml-2'>
            Cancelar
            </Button>
          </>
          ) : (
            <Button variant="primary" onClick={addMaquina}>
            Adicionar
            </Button>
          )
          }

          </Col>
          </Row>

          <h4>Lista de Máquinas/Benfeitorias</h4>
          <table className="table table-bordered table-striped mt-3">
          <thead>
          <tr>
          <th style={{ width: '80px' }}>#</th>
          <th>Descrição</th>
          <th>Marca</th>
          <th>Tipo</th>
          <th></th>
          </tr>
          </thead>
          <tbody>
          {inscricoes.length === 0 ? (
            <tr>
            <td colSpan={5} className='text-center'>Nenhum registro</td>
            </tr>
          ) : (
            maquinas.map(maquina => (
              <tr key={maquina.id} onClick={() => edit(maquina.id)} className='cursor-pointer'>
              <td>{maquina.id}</td>
              <td>{maquina.descricao}</td>
              <td>{maquina.marca}</td>
              <td>{maquina.tipo}</td>
              <td style={{width:"235px"}}> 
              <Button variant="secondary" onClick={() => detalheMaquina(maquina.id)} className='float-start btn-sm mr-3'>
              Editar
              </Button> 
              <Button variant="danger" onClick={() => removeMaquina(maquina.id)} className='float-start btn-sm'>
              Remover
              </Button>    
              </td>
              </tr>
            ))
          )}
          </tbody>
          </table> 
          </>
        )}      
        </Tab>

        <Tab eventKey="producao" title="Produção">
       
        {!isEditing && (
    <h5>Para habilitar esta aba conclua o cadastro inicial da fazenda.</h5>
  )}
  
  {isEditing && (
       <>
       <h4>Cadastrar Produção por Talhão</h4>
        <Row>
          <Col lg={3}>
            <div className="form-group">
              <label>Produto:</label>
              <select className="form-control" name="produto" value={newProducao.produto} onChange={handleProducao}>
                <option value="">Selecione</option>
                <option value="Soja">Soja</option>
                <option value="Milho">Milho</option>
                <option value="Feijao">Feijão</option>
                <option value="Sorgo">Sorgo</option>
                <option value="Milheto">Milheto</option>
                <option value="Trigo">Trigo</option>
              </select>
            </div>
          </Col>

          <Col lg={3}>
          <div className="form-group">
            <label>Sub Produto: </label>
            <select className="form-control" name="subProduto" value={newProducao.subProduto} onChange={handleProducao}>
              <option value="">Selecione</option>
              <option value="Grao">Grão</option>
              <option value="Farelo">Farelo</option>
              <option value="Oleo">Óleo</option>
            </select>
          </div>
        </Col>

        <Col lg={2}>
          <div className="form-group">
            <label>Armazenamento: </label>
            <select className="form-control" name="armazenamento" value={newProducao.armazenamento} onChange={handleProducao}>
              <option value="">Selecione</option>
              <option value="EmGrão">Em Grão</option>
              <option value="EmBag">Em Bag</option>
              <option value="EmSilo">Em Silo</option>
              <option value="EmGalpao">Em Galpão</option>
            </select>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group">
            <label>Unidade: </label>
            <select className="form-control" name="unidade" value={newProducao.unidade} onChange={handleProducao}>
              <option value="">Selecione</option>
              <option value="Kg">Kg</option>
              <option value="Ton">Ton</option>
              <option value="Saco">Saco</option>
            </select>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group">
            <label>Produtividade por Hectare: </label>
            <InputDecimal2Component placeholder="0,00" value={newProducao.produtividade} name="produtividade" onChange={handleProducao} min="0" step="0,01" />        
          </div>
        </Col>

        <Col lg={3}>
          <div className="form-group">
          <label>Talhão: </label>
          <select className="form-control" name="talhao" value={newProducao.talhao} onChange={handleProducao}>
            <option value="">Selecione</option>
            
            {talhoes.length === 0 ? (
              <option value="" disabled>Nenhum talhão disponível</option>
            ) : (
              talhoes.map(talhao => (
                <option key={talhao.id} value={talhao.id}>
                  {talhao.nome} - {talhao.area} (hectares)
                </option>
              ))
            )}
          </select>
          </div>
        </Col>

        <Col lg={3}>
          <div className="form-group">
            <label>Produção total por talhão, {unidadeProducaoSel}</label>          
            <input type="text" className="form-control" value={newProducao.valorTotal} name="valorTotal" onChange={handleProducao} readOnly />
          </div>
        </Col>
        <Col lg={3}>
          <div className="form-group">
            <label>Safra: </label>
            <select className="form-control" name="safra" value={newProducao.safra} onChange={handleProducao}>
              <option value="">Selecione a Safra</option>
              <option value="2023/2024">2023/2024</option>
              <option value="2024/2025">2024/2025</option>
              <option value="2025/2026">2025/2026</option>
            </select>
          </div>
        </Col>

        <Col lg={3}>
          <div className="form-group">
            <label>Variedade do Produto: </label>
            <input type="text" className="form-control" value={newProducao.variedadeProduto} placeholder="Variedade do Produto" name="variedadeProduto" onChange={handleProducao} />
          </div>
        </Col>

        <Col lg={3}>
          <div className="form-group">
            <label>Data de Plantio: </label>
            <input type="date" className="form-control" value={newProducao.dataPlantio} name="dataPlantio" onChange={handleProducao} />
          </div>
        </Col>
        <Col lg={3}>
          <div className="form-group">
            <label>Ciclo Estimado (em dias): </label>
            <input type="number" className="form-control" value={newProducao.cicloEstimado} placeholder="Ciclo Estimado" name="cicloEstimado" onChange={handleProducao} />
          </div>
        </Col>
        <Col lg={3}>
          <div className="form-group">
            <label>Data de Colheita Estimada: </label>
            <input type="date" className="form-control" value={newProducao.dataColheitaEstimada} name="dataColheitaEstimada" onChange={handleProducao} readOnly />
          </div>
        </Col>
        <Col lg={3}>
          <div className="form-group">
            <label>Data de Colheita: </label>
            <input type="date" className="form-control" value={newProducao.dataColheita} name="dataColheita" onChange={handleProducao} />
          </div>
        </Col>
      </Row>

      <Row>
          <Col lg={12} className='mt-1 float-right text-right'>
          {
          btEditaProducao ? (
          <>
            <Button variant="primary" onClick={editaProducao}>
            Editar
            </Button>
            <Button variant="light" onClick={cancelaProducao} className='ml-2'>
            Cancelar
            </Button>
          </>
          ) : (
            <Button variant="primary" onClick={addProducao}>
            Adicionar
            </Button>
          )
          }
          </Col>
          </Row>



          <h4>Lista de Produções</h4>
    <table className="table table-bordered table-striped mt-3">
    <thead>
    <tr>
    <th style={{ width: '80px' }}>#</th>
    <th>Produto</th>
    <th>Subproduto</th>
    <th>Armazenamento</th>
    <th></th>
    </tr>
    </thead>
    <tbody>
    {producoes.length === 0 ? (
      <tr>
      <td colSpan={5} className='text-center'>Nenhum registro</td>
      </tr>
    ) : (
      producoes.map(producao => (
        <tr key={producao.id} onClick={() => edit(producao.id)} className='cursor-pointer'>
        <td>{producao.id}</td>
        <td>{producao.produto}</td>
        <td>{producao.subProduto}</td>
        <td>{producao.armazenamento}</td>
        <td style={{width:"235px"}}> 
        <Button variant="secondary" onClick={() => detalheProducao(producao.id)} className='float-start btn-sm mr-3'>
        Editar
        </Button>    
        <Button variant="danger" onClick={() => removeProducao(producao.id)} className='float-start btn-sm'>
        Remover
        </Button>    
        </td>
        </tr>
      ))
    )}
    </tbody>
    </table> 
    </>

)}

        </Tab>
        </Tabs>
        
        </Modal.Body>
        <Modal.Footer>
        <Row className='cemPor'>
        <Button variant="secondary" onClick={handleClose} className='float-start'>
        Fechar
        </Button>
        </Row>
        </Modal.Footer>
        </Modal>
        <ToastContainer 
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
        />
        </>
      );
    }
    
    export default FazendasPage;