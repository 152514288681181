import Cookies from 'js-cookie';

const API_URL = 'https://bf-api.solutioweb.com.br/clientes';

export const getClienteById = async (id: number) => {
    try {
        const response = await fetch(`${API_URL}/${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Erro ao buscar cliente:', error);
        throw error;
    }
};

export const getClientes = async (search = '') => {
    try {
        const url = search ? `${API_URL}/lista?search=${encodeURIComponent(search)}` : `${API_URL}/lista`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Erro ao buscar lista de clientes:', error);
        throw error;
    }
};

export const createCliente = async (cliente: any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('nome_fantasia', cliente.nome_fantasia);
        formData.append('razao_social', cliente.razao_social);
        formData.append('cpf_cnpj', cliente.cpf_cnpj);
        formData.append('inscricao_estadual', cliente.inscricao_estadual);
        formData.append('cep', cliente.cep);
        formData.append('endereco', cliente.endereco);
        formData.append('numero', cliente.numero);
        formData.append('complemento', cliente.complemento);
        formData.append('bairro', cliente.bairro);
        formData.append('cidade', cliente.cidade);
        formData.append('uf', cliente.uf);
        formData.append('telefone_fixo', cliente.telefone_fixo);
        formData.append('celular', cliente.celular);
        formData.append('email', cliente.email);
        formData.append('dados_bancarios', cliente.dados_bancarios);
        formData.append('banco', cliente.banco);
        formData.append('agencia', cliente.agencia);
        formData.append('conta', cliente.conta);
        formData.append('titular_conta', cliente.titular_conta);
        formData.append('cpf_cnpj_titular', cliente.cpf_cnpj_titular);
        formData.append('tipo_cliente', cliente.tipo_cliente);
        formData.append('tipo_fornecedor', cliente.tipo_fornecedor);
        formData.append('tipo_transportador', cliente.tipo_transportador);
        formData.append('tipo_pessoa', cliente.tipo_pessoa);
        formData.append('antt', cliente.antt);

        const response = await fetch(API_URL, {
            method: 'POST',
            body: formData
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao criar cliente:', error);
        throw error;
    }
};

export const updateCliente = async (cliente: any) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('nome_fantasia', cliente.nome_fantasia);
        formData.append('razao_social', cliente.razao_social);
        formData.append('cpf_cnpj', cliente.cpf_cnpj);
        formData.append('inscricao_estadual', cliente.inscricao_estadual);
        formData.append('cep', cliente.cep);
        formData.append('endereco', cliente.endereco);
        formData.append('numero', cliente.numero);
        formData.append('complemento', cliente.complemento);
        formData.append('bairro', cliente.bairro);
        formData.append('cidade', cliente.cidade);
        formData.append('uf', cliente.uf);
        formData.append('telefone_fixo', cliente.telefone_fixo);
        formData.append('celular', cliente.celular);
        formData.append('email', cliente.email);
        formData.append('dados_bancarios', cliente.dados_bancarios);
        formData.append('banco', cliente.banco);
        formData.append('agencia', cliente.agencia);
        formData.append('conta', cliente.conta);
        formData.append('titular_conta', cliente.titular_conta);
        formData.append('cpf_cnpj_titular', cliente.cpf_cnpj_titular);
        formData.append('tipo_cliente', cliente.tipo_cliente);
        formData.append('tipo_fornecedor', cliente.tipo_fornecedor);
        formData.append('tipo_transportador', cliente.tipo_transportador);
        formData.append('tipo_pessoa', cliente.tipo_pessoa);
        formData.append('antt', cliente.antt);

        const response = await fetch(`${API_URL}/${cliente.id}`, {
            method: 'PUT',
            body: formData
        });

        if (!response.ok) {
            return { 'error': 'Houve uma falha. Tente mais tarde.' };
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Erro ao atualizar cliente:', error);
        throw error;
    }
};

export const deleteCliente = async (id: number) => {
    try {
        const token = Cookies.get("TokenBrasao");

        const formData = new FormData();
        formData.append('id', id.toString());

        const response = await fetch(`${API_URL}/delete`, {
            method: 'POST',
            body: formData
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao deletar cliente:', error);
        throw error;
    }
};