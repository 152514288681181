import Cookies from 'js-cookie';

const API_URL = 'https://bf-api.solutioweb.com.br/users';

export const getUserById = async (id:number) => {
    try {
        const response = await fetch(API_URL+'/grupo-economico/'+id);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

export const getUsers = async (search = '') => {
    try {
        const url = search ? `${API_URL}/grupo-economico?search=${encodeURIComponent(search)}` : API_URL+'/grupo-economico';
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

export const createUser = async (user: { nome: string; email: string; cpf: string; }) => {
    try {

        const idUser = Cookies.get('TokenBrasao') as string;

        const formData = new FormData();
        formData.append('nome', user.nome);
        formData.append('email', user.email);
        formData.append('senha', '123456');
        formData.append('cpf', user.cpf);
        formData.append('idUser', idUser);
        
        const response = await fetch(API_URL+'/grupo-economico', {
            method: 'POST',
            body: formData
        });
        
        if (!response.ok) {
            return {'error':'Houve uma falha. Tente mais tarde.'};
        }
        
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};

export const updateUser = async (user:any) => {
    try {
        const formData = new FormData();
        formData.append('nome', user.nome);
        formData.append('email', user.email);
        formData.append('cpf', user.cpf);
        
        const response = await fetch(API_URL+'/grupo-economico'+'/'+user.id, {
            method: 'PUT',
            body: formData
        });
        
        if (!response.ok) {
            return {'error':'Houve uma falha. Tente mais tarde.'};
        }
        
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};