import React, { useState, useEffect } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponent from '../components/MenuComponent';
import { useNavigate } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { IoAddCircleOutline } from "react-icons/io5";
import { Modal, Button, Alert } from 'react-bootstrap';
import { IoSearch } from "react-icons/io5";
import { getUsers, getUserById, createUser, updateUser } from '../services/grupoEconomicoService';
import { Slide, ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';

export interface User {
  id: number;
  nome: string;
  email: string;
  senha: string;
  tipo: string;
  cpf: string;
  idUser: string;
  data_cadastro: string;
  data_alteracao: string;
}

const GruposEconomicosPage: React.FC = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [newUser, setNewUser] = useState({ id: null, nome: '', email: '', cpf: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [alerta, setAlerta] = useState('');
  const initialUserState = { id: null, nome: '', email: '', cpf: '' };
  const [search, setSearch] = useState('');

  const handleClose = () => setShow(false);

  const add = () => {
    setNewUser(initialUserState);
    setIsEditing(false);
    setShow(true);
  };

  const edit = async (id: number) => {
    try {
      const user = await getUserById(id);
      setNewUser({ id: user.id, nome: user.nome, email: user.email, cpf: user.cpf });
      setIsEditing(true);
      setShow(true);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const notify = () => toast.info(alerta);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);

  const fetchUsers = async (search = '') => {
    try {
      const users = await getUsers(search);

      const idUser = Cookies.get('TokenBrasao');

      const filteredUsers = users.filter((user: User) => user.idUser == idUser);

      setUsers(filteredUsers);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === '' || search.trim().length >= 3) {
      fetchUsers(search.trim());
    } else {
      setAlerta('O termo de pesquisa deve ter pelo menos 3 caracteres.');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === '') {
      fetchUsers('');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleCPFChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const unformattedCPF = event.target.value;
    
    const formattedCPF = unformattedCPF.replace(/[^0-9]/g, '');
    
    const maxLength = 11;
    const truncatedCPF = formattedCPF.slice(0, maxLength);
    
    const maskedCPF = truncatedCPF.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      '$1.$2.$3-$4'
    );
    
    setNewUser({ ...newUser, cpf: maskedCPF });
  };

  const handleSubmit = async () => {
    setAlerta('');

    await new Promise(resolve => setTimeout(resolve, 0));

    if (!newUser.nome.trim() || !newUser.email.trim() || (!isEditing && !newUser.cpf.trim())) {
      setAlerta('Todos os campos são obrigatórios.');
      return;
    }

    if (newUser.nome.trim().length < 3) {
      setAlerta('O nome deve ter pelo menos 3 caracteres.');
      return;
    }

    if (!validateEmail(newUser.email.trim())) {
      setAlerta('O email deve ser válido.');
      return;
    }

    try {
      let result: any;

      if (isEditing) {
        result = await updateUser(newUser);
      } else {
        result = await createUser(newUser);
      }

      if (result.error) {
        setAlerta(result.error);
      } else {
        if (isEditing) {
          setUsers(users.map(user => (user.id === newUser.id ? result : user)));
        } else {
          setUsers([...users, result]);
        }
        handleClose();
        setAlerta(isEditing ? 'Usuário atualizado com sucesso.' : 'Usuário cadastrado com sucesso.');
      }

    } catch (error) {
      setAlerta('Não foi possível realizar esta operação.');
    }
  };

  return (
    <>
      <HeaderComponent />
      <div className='page-content'>
        <MenuComponent />
        <div className='content-wrapper'>
          <div className='content-inner'>
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a onClick={() => navigate('/')} className="breadcrumb-item">
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">Grupos Econômicos</span>
                  </div>
                  <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more"></i></a>
                </div>
              </div>
            </div>
            <div className='content'>
              <div className='card'>
                <div className="card-header">
                  <h5 className="card-title d-flex float-left">
                    <Button className='bt-1-ico' variant="indigo" onClick={add}><IoAddCircleOutline className='m-0-auto fs-2em' /></Button>
                    <span className='fs-1-5em pl-2'>Grupos Econômicos</span>
                  </h5>
                  <div className='float-right'>
                    <form onSubmit={handleSearchSubmit}>
                      <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Pesquisar por nome ou email..."
                          value={search}
                          onChange={handleSearchChange}
                        />
                        <div className="form-control-feedback form-control-feedback-lg pt-0">
                          <button type="submit" className="btn btn-link p-0">
                            <IoSearch className='fs-1-7em' />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='card-body'>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style={{ width: '80px' }}>#</th>
                          <th>Nome</th>
                          <th>E-mail</th>
                          <th>CPF</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map(user => (
                          <tr key={user.id} onClick={() => edit(user.id)} className='cursor-pointer'>
                            <td>{user.id}</td>
                            <td>{user.nome}</td>
                            <td>{user.email}</td>
                            <td>{user.cpf}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{isEditing ? 'Editar usuário' : 'Novo usuário'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Nome: </label>
            <input type="text" className="form-control" placeholder="Nome" name="nome" value={newUser.nome} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>E-mail: </label>
            <input type="text" className="form-control" placeholder="E-mail" name="email" value={newUser.email} onChange={handleInputChange} />
          </div>
          <div className="form-group" style={{ display: isEditing ? 'none' : 'block' }}>
            <label>CPF: </label>
            <input type="text" className="form-control" placeholder="CPF" name="cpf" value={newUser.cpf} onChange={handleCPFChange}
    onKeyUp={(e) => {
      if (/[^0-9\.\/]/g.test(e.key)) {
        e.preventDefault();
      }
    }} />
          </div>

          <Alert variant="info">
            A senha criada para este usuário será: <b>123456</b>
        </Alert>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEditing ? 'Atualizar' : 'Salvar'}
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer 
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
}

export default GruposEconomicosPage;