import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min';

const InputDecimal2Component: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const $input = ($ as any)(inputRef.current);
      $input.mask('###.###.###.###.###.###.###.###.###.###.###,00', { reverse: true });

      const handleInput = (e: any) => {
        const value = e.target.value;
        const name = props.name || '';
        props.onChange && props.onChange({ target: { name, value } } as any);
      };

      $input.on('change', handleInput);

      return () => {
        $input.off('change', handleInput);
      };
    }
  }, [props]);

  return (
    <input
      className='form-control'
      type="text"
      ref={inputRef}
      {...props}
    />
  );
};

export default InputDecimal2Component;