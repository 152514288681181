import React, { useState, useEffect } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponent from '../components/MenuComponent';
import { useNavigate } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { Button, Col, Row } from 'react-bootstrap';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { updtPass } from '../services/userService';
import 'react-toastify/dist/ReactToastify.css';

const AlterarsenhaPage: React.FC = () => {
    const navigate = useNavigate();
    
    const [disabled, setDisabled] = useState(false);
    const [alerta, setAlerta] = useState('');
    const [camposForm, setCamposForm] = useState({ senhaAtual: '', novaSenha: '', novaSenhaConfirma: ''});
    
    const edit = async () => {
        setAlerta('');

        try {
            if (camposForm.senhaAtual === '' || camposForm.novaSenha === '' || camposForm.novaSenhaConfirma === '') {
             
                setAlerta('Todos os campos são obrigatórios.');
            
            }else if (camposForm.novaSenha.length < 6) {
            
                setAlerta('A nova senha deve ter mais de 5 caracteres.');
            
            }else if (camposForm.novaSenha !== camposForm.novaSenhaConfirma) {
            
                setAlerta('As novas senhas não coincidem.');
            
            }else{

                setDisabled(true);
                
                const result = await updtPass(camposForm);
                
                if (result) {
                    setAlerta(result.message);
                    setCamposForm({ senhaAtual: '', novaSenha: '', novaSenhaConfirma: ''});
                }

                setDisabled(false);
            }
        } catch (error) {
            console.error('Error fetching fazenda:', error);
            setDisabled(false);
        }
    };    
    
    const notify = () => toast.info(alerta);
    
    useEffect(() => {
        if (alerta) {
            notify();
        }
    }, [alerta]);
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setCamposForm({
            ...camposForm,
            [name]: value
        });
    };
    
    return (
        <>
            <HeaderComponent />
            <div className='page-content'>
                <MenuComponent />
                <div className='content-wrapper'>
                    <div className='content-inner'>
                        <div className="page-header page-header-light">
                            <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                                <div className="d-flex">
                                    <div className="breadcrumb">
                                        <a onClick={() => navigate('/')} className="breadcrumb-item">
                                            <IoMdHome /> &nbsp; Início
                                        </a>
                                        <span className="breadcrumb-item active">Alterar senha</span>
                                    </div>
                                    <a href="#" className="header-elements-toggle text-body d-lg-none">
                                        <i className="icon-more"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='content'>
                            <div className='card'>
                                <div className="card-header">
                                    <h5 className="card-title d-flex float-left">
                                        <span className='fs-1-5em pl-2'>Alterar senha</span>
                                    </h5>
                                </div>
                                <div className='card-body'>
                                    <Row className="d-lg-flex flex-lg-row d-flex flex-column">
                                        <Col lg={3}>
                                            <div className="form-group">
                                                <label>Senha atual: </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Senha atual"
                                                    name="senhaAtual"
                                                    value={camposForm.senhaAtual}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-group">
                                                <label>Nova senha: </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Nova senha"
                                                    name="novaSenha"
                                                    value={camposForm.novaSenha}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-group">
                                                <label>Confirme a nova senha: </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Confirme a nova senha"
                                                    name="novaSenhaConfirma"
                                                    value={camposForm.novaSenhaConfirma}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="form-group mt-4">
                                                <Button variant="primary" className='btn-block' disabled={disabled} onClick={edit}>
                                                    Alterar senha
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer 
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
            />
        </>
    );
}

export default AlterarsenhaPage;