import Cookies from 'js-cookie';

const API_URL = 'https://bf-api.solutioweb.com.br/users';

export const updtPass = async (camposForm:any) => {
  try{

    const token = Cookies.get("TokenBrasao") as string;

    const formData = new FormData();
    
    const appendField = (key:any, value:any) => {
        
      formData.append(key, value ?? '');
    };

    appendField('id', token);
    appendField('senhaAtual', camposForm.senhaAtual);
    appendField('novaSenha', camposForm.novaSenha);
    appendField('novaSenhaConfirma', camposForm.novaSenhaConfirma);

    const response = await fetch(API_URL+'/update-password', {
      method: 'POST',
      body: formData
    });

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Houve uma falha:', error);
    throw error;
  }
}

export const getUserById = async (id:number) => {
  try {
    const response = await fetch(API_URL+'/'+id);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getUsers = async (search = '') => {
  try {
    const token = Cookies.get("TokenBrasao") as string;
    const url = search ? `${API_URL}/list/${token}?search=${encodeURIComponent(search)}` : API_URL+'/list/'+token;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const createUser = async (user: { 
  nome: string;
  email: string;
  senha: string;
  tipo: string;
  uf: string;
  cidade: string;
  bairro: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  nascimento: string;
  telefone: string;
  celular: string;
  cpf: string;
 }) => {
  try {
    const formData = new FormData();
    
    const appendField = (key:any, value:any) => {
        
      formData.append(key, value ?? '');
    };

    appendField('nome', user.nome);
    appendField('email', user.email);
    appendField('tipo', user.tipo);
    appendField('uf', user.uf);
    appendField('senha', user.senha);
    appendField('cidade', user.cidade);
    appendField('bairro', user.bairro);
    appendField('cep', user.cep);
    appendField('endereco', user.endereco);
    appendField('numero', user.numero);
    appendField('complemento', user.complemento);
    appendField('nascimento', user.nascimento);  
    appendField('telefone', user.telefone);  
    appendField('celular', user.celular);  
    appendField('nascimento', user.nascimento);    
    appendField('cpf', user.cpf);    

    const response = await fetch(API_URL, {
      method: 'POST',
      body: formData
    });

    if (!response.ok) {
      return {'error':'Houve uma falha. Tente mais tarde.'};
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const updateUser = async (user:any) => {
    try {
      const formData = new FormData();

      const appendField = (key:any, value:any) => {
        
        formData.append(key, value ?? '');
      };

      appendField('nome', user.nome);
      appendField('email', user.email);
      appendField('tipo', user.tipo);
      appendField('uf', user.uf);
      appendField('cidade', user.cidade);
      appendField('bairro', user.bairro);
      appendField('cep', user.cep);
      appendField('endereco', user.endereco);
      appendField('numero', user.numero);
      appendField('complemento', user.complemento);
      appendField('nascimento', user.nascimento);  
      appendField('telefone', user.telefone);  
      appendField('celular', user.celular);  
      appendField('nascimento', user.nascimento);    
      appendField('cpf', user.cpf);  

      const response = await fetch(API_URL+'/'+user.id, {
        method: 'PUT',
        body: formData
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  };

  export const auth = async (email: string, senha: string) => {
    try {

      const formData = new FormData();
      formData.append('email', email);
      formData.append('senha', senha);

      const response = await fetch(`${API_URL}/auth`, {
        method: 'POST',
        body: formData
      });
  
      const data = await response.json();
  
      return data;
    } catch (error) {
      console.error('Error authenticating user:', error);
      throw error;
    }
  };