import React, { useState, useEffect } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponent from '../components/MenuComponent';
import { useNavigate } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { Modal, Button, Form } from 'react-bootstrap';
import { IoSearch } from "react-icons/io5";
import { getUsers, getUserById, createUser, updateUser } from '../services/grupoEconomicoService';
import { getInscricoesEstaduais, getInscricoesEstaduaisUsuario, permissao } from '../services/inscricaoEstadualService';
import { Slide, ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';

export interface User {
  id: number;
  nome: string;
  email: string;
  senha: string;
  tipo: string;
  cpf: string;
  idUser: string;
  data_cadastro: string;
  data_alteracao: string;
}

export interface Inscricao{
  id: number;
  cnpj: string;
  fazenda_nome: string;
  idFazenda: number;
}

export interface Fazenda{
  id: number;
}

const PermissoesPage: React.FC = () => {

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [newUser, setNewUser] = useState({ id: null, nome: '', email: '', cpf: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [alerta, setAlerta] = useState('');
  const initialUserState = { id: null, nome: '', email: '', cpf: '' };
  const [search, setSearch] = useState('');
  const [inscricoesPermissoesLista, setInscricoesPermissoesLista] = useState<Inscricao[]>([]);
  const [inscricoesPermissoesListaUsuario, setInscricoesPermissoesListaUsuario] = useState<Inscricao[]>([]);
  const [selectedInscricaoId, setSelectedInscricaoId] = useState(null);
  
  let fazendasArray:Fazenda[];

  const handleClose = () => setShow(false);

  const addPermissao = (id:null) => {
    
    if(selectedInscricaoId && id){

      const permissaoToggle = permissao(id, parseInt(selectedInscricaoId));
      edit(id);
    }
  };

  const listaFazendas = Cookies.get('FazendaLista');

  if(listaFazendas){

    fazendasArray = JSON.parse(listaFazendas);
  }

  const removePermissao = (idUsuario:any, idInscricao:any) => {
   
    const permissaoToggle = permissao(idUsuario, idInscricao);
   
    permissao(idUsuario, idInscricao).then(() => {
      setInscricoesPermissoesListaUsuario(prevState =>
        prevState.filter(inscricao => inscricao.id !== idInscricao)
      );
    }).catch(error => {
      console.error('Failed to remove permission:', error);
    });
  }

  const handleSelectChange = (event:any) => {
    
    setSelectedInscricaoId(event.target.value);
  };

  const edit = async (id: number) => {
    try {
      const user = await getUserById(id);

      const inscricoesPermissoes:Inscricao[] = await getInscricoesEstaduais(id);
      const inscricoesPermissoesUsuário = await getInscricoesEstaduaisUsuario(id);

      const fazendaIds = fazendasArray.map(fazenda => fazenda.id);

      const filteredInscricoesPermissoes = inscricoesPermissoes.filter(inscricao =>
        fazendaIds.includes(inscricao.idFazenda)
      );

      setInscricoesPermissoesLista(filteredInscricoesPermissoes);
      setInscricoesPermissoesListaUsuario(inscricoesPermissoesUsuário);

      setNewUser({ id: user.id, nome: user.nome, email: user.email, cpf: user.cpf });
      setIsEditing(true);
      setShow(true);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const notify = () => toast.info(alerta);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);

  const fetchUsers = async (search = '') => {
    try {
      const users = await getUsers(search);

      const idUser = Cookies.get('TokenBrasao');

      const filteredUsers = users.filter((user: User) => user.idUser == idUser);

      setUsers(filteredUsers);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === '' || search.trim().length >= 3) {
      fetchUsers(search.trim());
    } else {
      setAlerta('O termo de pesquisa deve ter pelo menos 3 caracteres.');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === '') {
      fetchUsers('');
    }
  };

  useEffect(() => {

    fetchUsers();
  }, []);

  return (
    <>
      <HeaderComponent />
      <div className='page-content'>
        <MenuComponent />
        <div className='content-wrapper'>
          <div className='content-inner'>
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a onClick={() => navigate('/')} className="breadcrumb-item">
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">Permissões</span>
                  </div>
                  <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more"></i></a>
                </div>
              </div>
            </div>
            <div className='content'>
              <div className='card'>
                <div className="card-header">
                  <h5 className="card-title d-flex float-left">
                    <span className='fs-1-5em pl-2'>Permissões</span>
                  </h5>
                  <div className='float-right'>
                    <form onSubmit={handleSearchSubmit}>
                      <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Pesquisar por nome ou email..."
                          value={search}
                          onChange={handleSearchChange}
                        />
                        <div className="form-control-feedback form-control-feedback-lg pt-0">
                          <button type="submit" className="btn btn-link p-0">
                            <IoSearch className='fs-1-7em' />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='card-body'>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style={{ width: '80px' }}>#</th>
                          <th>Nome</th>
                          <th>E-mail</th>
                          <th>CPF</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map(user => (
                          <tr key={user.id} onClick={() => edit(user.id)} className='cursor-pointer'>
                            <td>{user.id}</td>
                            <td>{user.nome}</td>
                            <td>{user.email}</td>
                            <td>{user.cpf}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Permissões deste usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <h6>{newUser.nome} - {newUser.email}</h6>
          </div>
          <div className='form-group d-flex'>
          <Form.Select aria-label="Default select example" className='form-control mr-3' onChange={handleSelectChange}>
            <option value="">Inscrições estaduais</option>
            {inscricoesPermissoesLista && inscricoesPermissoesLista.length > 0 ? (
              inscricoesPermissoesLista.map(inscricao => (
                <option key={`${inscricao.id}`} value={inscricao.id}>
                  {inscricao.fazenda_nome} - {inscricao.cnpj}
                </option>
              ))
            ) : (
              <option>Nenhum registro</option>
            )}
          </Form.Select>
          <Button variant="primary" onClick={() => addPermissao(newUser.id)}>
            Incluir
          </Button>
          </div>
          <div>
          <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th colSpan={1}>Fazenda - CNPJ Inscrição</th>
                  <th>
                  </th>
                </tr>
              </thead>
              <tbody>
              {inscricoesPermissoesListaUsuario && inscricoesPermissoesListaUsuario.length > 0 ? (
                inscricoesPermissoesListaUsuario.map(inscricao => (
                  <tr key={inscricao.cnpj} className='cursor-pointer'>
                    <td>{inscricao.fazenda_nome} - {inscricao.cnpj}</td>
                    <td style={{width:"140px"}}>
                      <Button variant="danger" size="sm" onClick={() => removePermissao(newUser.id, inscricao.id)}>
                        Remover
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Nenhum registro</td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer 
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
}

export default PermissoesPage;