import React, { useState, useEffect } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponent from '../components/MenuComponent';
import { useNavigate } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { IoAddCircleOutline } from "react-icons/io5";
import { Modal, Button } from 'react-bootstrap';
import { IoSearch } from "react-icons/io5";
import { getInscricoesEstaduais, getInscricaoEstadualById, createInscricaoEstadual, updateInscricaoEstadual } from '../services/inscricaoEstadualService';
import { getFazendas } from '../services/fazendaService';
import { Slide, ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';

export interface InscricaoEstadual {
  id: number;
  idFazenda: number;
  cnpj: string;
  fazendaNome?: string;
  data_cadastro: string;
  data_alteracao: string;
}

export interface Fazenda {
  id: number;
  nome: string;
  inscricao: Inscricao;
  idUser: string;
}

export interface Inscricao {
  idFazenda: number;
  idUser: string;
}

const InscricoesEstaduaisPage: React.FC = () => {
  const navigate = useNavigate();
  
  const [show, setShow] = useState(false);
  const [inscricoes, setInscricoes] = useState<InscricaoEstadual[]>([]);
  const [fazendas, setFazendas] = useState<Fazenda[]>([]);
  const [newInscricao, setNewInscricao] = useState({ id: null, idFazenda: null, cnpj: '', fazendaNome: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [alerta, setAlerta] = useState('');
  const initialInscricaoState = { id: null, idFazenda: null, cnpj: '', fazendaNome: ''  };
  const [search, setSearch] = useState('');
  
  const handleClose = () => setShow(false);
  
  const add = () => {
    setNewInscricao(initialInscricaoState);
    setIsEditing(false);
    setShow(true);
  };  
  
  const handleCNPJChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const unformattedCNPJ = event.target.value;
    
    const formattedCNPJ = unformattedCNPJ.replace(/[^0-9]/g, '');
    
    const maxLength = 14;
    const truncatedCNPJ = formattedCNPJ.slice(0, maxLength);
    
    const maskedCNPJ = truncatedCNPJ.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})(\d{2})$/,
      '$1.$2.$3/$4$5-$6'
    );
    
    setNewInscricao({ ...newInscricao, cnpj: maskedCNPJ });
  };
  
  const edit = async (id: number) => {
    try {
      const inscricao = await getInscricaoEstadualById(id);
      setNewInscricao({ id: inscricao.id, idFazenda: inscricao.idFazenda, cnpj: inscricao.cnpj, fazendaNome: inscricao.fazendaNome });
      setIsEditing(true);
      setShow(true);
    } catch (error) {
      console.error('Error fetching inscrição estadual:', error);
    }
  };
  
  const notify = () => toast.info(alerta);
  
  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);
  
  const fetchInscricoesEstaduais = async (search = '') => {
    try {
      const inscricoes = await getInscricoesEstaduais(search);
      const fazendas = await getFazendas();
  
      const idUser = Cookies.get('TokenBrasao');
  
      const filteredFazendas = fazendas.filter((fazenda: Fazenda) => fazenda.idUser == idUser);
      const filteredFazendaIds = filteredFazendas.map((fazenda: Fazenda) => fazenda.id); // Extraímos apenas os IDs das fazendas filtradas
  
      const filteredInscricoes = inscricoes.filter((inscricao: InscricaoEstadual) => 
        filteredFazendaIds.includes(inscricao.idFazenda)
      );
  
      const inscricoesComNomeFazenda = filteredInscricoes.map((inscricao: InscricaoEstadual) => {
        const fazenda = fazendas.find((f: Fazenda) => f.id === inscricao.idFazenda);
        return { ...inscricao, fazendaNome: fazenda ? fazenda.nome : 'N/A' };
      });
  
      setInscricoes(inscricoesComNomeFazenda);
      setFazendas(filteredFazendas);
    } catch (error) {
      console.error('Error fetching inscrições estaduais:', error);
    }
  };  
  
  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === '' || search.trim().length >= 3) {
      fetchInscricoesEstaduais(search.trim());
    } else {
      setAlerta('O termo de pesquisa deve ter pelo menos 3 caracteres.');
    }
  };
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === '') {
      fetchInscricoesEstaduais('');
    }
  };
  
  useEffect(() => {
    fetchInscricoesEstaduais();
  }, []);
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewInscricao({ ...newInscricao, [name]: value });
  };
  
  const handleSubmit = async () => {
    setAlerta('');
    
    await new Promise(resolve => setTimeout(resolve, 0));
    
    if (!newInscricao.cnpj.trim()) {
      setAlerta('Todos os campos são obrigatórios.');
      return;
    }
    
    if (newInscricao.cnpj.trim().length !== 18) {
      setAlerta('O CNPJ deve ter 18 caracteres.');
      return;
    }
    
    try {
      let result: any;
      
      if (isEditing) {
        result = await updateInscricaoEstadual(newInscricao);
      } else {
        result = await createInscricaoEstadual(newInscricao);
      }
      
      if (result.error) {
        setAlerta(result.error);
      } else {
        if (isEditing) {
          console.log(result);
          setInscricoes(inscricoes.map(inscricao => (inscricao.id === newInscricao.id ? result : inscricao)));
        } else {
          setInscricoes([...inscricoes, result]);
          console.log(inscricoes);
        }
        handleClose();
        setAlerta(isEditing ? 'Inscrição Estadual atualizada com sucesso.' : 'Inscrição Estadual cadastrada com sucesso.');
      }
      
    } catch (error) {
      setAlerta('Não foi possível realizar esta operação.');
    }
  };
  
  return (
    <>
    <HeaderComponent />
    <div className='page-content'>
    <MenuComponent />
    <div className='content-wrapper'>
    <div className='content-inner'>
    <div className="page-header page-header-light">
    <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
    <div className="d-flex">
    <div className="breadcrumb">
    <a onClick={() => navigate('/')} className="breadcrumb-item">
    <IoMdHome /> &nbsp; Início
    </a>
    <span className="breadcrumb-item active">Inscrições Estaduais</span>
    </div>
    <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more"></i></a>
    </div>
    </div>
    </div>
    <div className='content'>
    <div className='card'>
    <div className="card-header">
    <h5 className="card-title d-flex float-left">
    <Button className='bt-1-ico' variant="indigo" onClick={add}><IoAddCircleOutline className='m-0-auto fs-2em' /></Button>
    <span className='fs-1-5em pl-2'>Inscrições Estaduais</span>
    </h5>
    <div className='float-right'>
    <form onSubmit={handleSearchSubmit}>
    <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
    <input
    type="text"
    className="form-control form-control-lg"
    placeholder="Pesquisar por CNPJ ou nome da fazenda..."
    value={search}
    onChange={handleSearchChange}
    />
    <div className="form-control-feedback form-control-feedback-lg pt-0">
    <button type="submit" className="btn btn-link p-0">
    <IoSearch className='fs-1-7em' />
    </button>
    </div>
    </div>
    </form>
    </div>
    </div>
    <div className='card-body'>
    <div className="table-responsive">
    <table className="table table-bordered table-striped">
    <thead>
    <tr>
    <th style={{ width: '80px' }}>#</th>
    <th>Inscrição Estadual</th>
    <th>Fazenda</th>
    </tr>
    </thead>
    <tbody>
    {inscricoes.map(inscricao => (
      <tr key={inscricao.id} onClick={() => edit(inscricao.id)} className='cursor-pointer'>
      <td>{inscricao.id}</td>
      <td>{inscricao.cnpj}</td>
      <td>{inscricao.fazendaNome}</td>
      </tr>
    ))}
    </tbody>
    </table>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <Modal show={show} onHide={handleClose}>
    <Modal.Header>
    <Modal.Title>{isEditing ? 'Editar inscrição estadual' : 'Nova inscrição estadual'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className="form-group">
    <label>Fazenda: </label>
    <select className="form-control" name="idFazenda" value={newInscricao.idFazenda || ''} onChange={handleInputChange}>
    <option value="">Selecione uma fazenda</option>
    {fazendas.map(fazenda => (
      <option key={fazenda.id} value={fazenda.id}>{fazenda.nome}</option>
    ))}
    </select>
    </div>
    <div className="form-group">
    <label>CNPJ: </label>
    <input
    type="text"
    className="form-control"
    placeholder="CNPJ"
    name="cnpj"
    value={newInscricao.cnpj}
    onChange={handleCNPJChange}
    onKeyUp={(e) => {
      if (/[^0-9\.\/]/g.test(e.key)) {
        e.preventDefault();
      }
    }}
    />
    </div>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
    Fechar
    </Button>
    <Button variant="primary" onClick={handleSubmit}>
    {isEditing ? 'Atualizar' : 'Salvar'}
    </Button>
    </Modal.Footer>
    </Modal>
    <ToastContainer 
    position="top-center"
    autoClose={2000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    transition={Slide}
    />
    </>
  );
}

export default InscricoesEstaduaisPage;