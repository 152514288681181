import React from 'react';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponent from '../components/MenuComponent';

const HomePage: React.FC = () => {
  return (
    <>
      <HeaderComponent />
      <div className='page-content'>
        <div className='sidebar sidebar-light sidebar-main sidebar-expand-lg'>
          <div className='sidebar-content'>
            <MenuComponent />
          </div>
        </div>
        <main>
        </main>
      </div>
    </>
  );
}

export default HomePage;