import React, { useState, useEffect } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponent from '../components/MenuComponent';
import InputDecimal2Component from '../components/Inputdecimal2Component';
import { useNavigate } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { IoAddCircleOutline } from "react-icons/io5";
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { IoSearch } from "react-icons/io5";
import { getContas, getContaById, createConta, updateConta } from '../services/contasBancariasService';
import { getFazendas } from '../services/fazendaService';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import Cookies from 'js-cookie';

export interface ContaBancaria {
  id: number;
  descricao: string;
  codigo_banco: string;
  agencia: string;
  conta: string;
  saldo_inicial: string;
  saldo_atual: string;
  tipo_conta: string;
  nome_titular: string;
  cpf_titular: string;
  nome_segundo_titular?: string;
  cpf_segundo_titular?: string;
  fazendas: string[];
}

export interface Fazenda {
  id: number;
  nome: string;
  idUser: string;
  valor_hectare: string;
  data_cadastro: string;
  data_alteracao: string;
}

const ContasBancariasPage: React.FC = () => {

  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

  const [fazendas, setFazendas] = useState<Fazenda[]>([]);

  const [selectedFazendas, setSelectedFazendas] = useState<{ value: string; label: string }[]>([]);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [contas, setContas] = useState<ContaBancaria[]>([]);
  const [newConta, setNewConta] = useState<ContaBancaria>({
    id: 0,
    descricao: '',
    codigo_banco: '',
    agencia: '',
    conta: '',
    saldo_inicial: '',
    saldo_atual: '',
    tipo_conta: 'individual',
    nome_titular: '',
    cpf_titular: '',
    nome_segundo_titular: '',
    cpf_segundo_titular: '',
    fazendas: [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [alerta, setAlerta] = useState('');
  const initialContaState = {
    id: 0,
    descricao: '',
    codigo_banco: '',
    agencia: '',
    conta: '',
    saldo_inicial: '',
    saldo_atual: '',
    tipo_conta: '',
    nome_titular: '',
    cpf_titular: '',
    fazendas: [],
  };
  const [search, setSearch] = useState('');

  const fetchFazendas = async (search = '') => {
    try {
      if (isEditing === false) {
        const fazendas = await getFazendas(search);
        const idUser = Cookies.get('TokenBrasao');
        const filteredFazendas = fazendas.filter((fazenda: Fazenda) => fazenda.idUser == idUser);
        
        const fazendaOptions = filteredFazendas.map((fazenda: Fazenda) => ({
          value: fazenda.id, 
          label: fazenda.nome, 
        }));
        
        setFazendas(filteredFazendas);
        setOptions(fazendaOptions); 
      }
    } catch (error) {
      console.error('Error fetching fazendas:', error);
    }
  };

  useEffect(() => {
    fetchFazendas();
  }, []);

  const handleClose = () => setShow(false);

  const add = () => {
    setNewConta(initialContaState);
    setIsEditing(false);
    setShow(true);

    setNewConta({
      id: 0,
      descricao: '',
      codigo_banco: '',
      agencia: '',
      conta: '',
      saldo_inicial: '',
      saldo_atual: '',
      tipo_conta: '',
      nome_titular: '',
      cpf_titular: '',
      fazendas: [],
    });

    setSelectedFazendas([]);
  };

  const edit = async (id: number) => {
    try {
      const conta = await getContaById(id);
  
      const fazendaIds = conta.fazendas ? JSON.parse(conta.fazendas) : [];
  
      const selectedFazendas = fazendaIds.length > 0 
        ? options.filter(option => fazendaIds.includes(option.value)) 
        : [];
  
      setNewConta({
        id: conta.id,
        descricao: conta.descricao,
        codigo_banco: conta.codigo_banco,
        agencia: conta.agencia,
        conta: conta.conta,
        saldo_inicial: conta.saldo_inicial,
        saldo_atual: conta.saldo_atual,
        tipo_conta: conta.tipo_conta,
        nome_titular: conta.nome_titular,
        cpf_titular: conta.cpf_titular,
        nome_segundo_titular: conta.nome_segundo_titular,
        cpf_segundo_titular: conta.cpf_segundo_titular,
        fazendas: fazendaIds,
      });
  
      setSelectedFazendas(selectedFazendas);
      
      setIsEditing(true);
      setShow(true);
    } catch (error) {
      console.error('Error fetching conta bancaria:', error);
    }
  };  

  const notify = () => toast.info(alerta);

  const style = document.createElement("style");
  style.innerHTML = `
      .modal-dialog{
        min-width: 90%;
      }
    `;
  document.head.appendChild(style);

  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);

  const fetchContas = async (search = '') => {
    try {
      const contas = await getContas(search);
      const fazendas = await getFazendas();
      const idUser = Cookies.get('TokenBrasao');
  
      const filteredFazendas = fazendas.filter((fazenda: Fazenda) => fazenda.idUser == idUser);
      const filteredFazendaIds = filteredFazendas.map((fazenda: Fazenda) => String(fazenda.id));
  
      const filteredContas = contas.filter((conta: ContaBancaria) => {
        let fazendaIds: string[] = [];
  
        if (typeof conta.fazendas === 'string') {
          fazendaIds = JSON.parse(conta.fazendas);
        } else if (Array.isArray(conta.fazendas)) {
          fazendaIds = conta.fazendas;
        }
  
        fazendaIds = fazendaIds.map((id: number | string) => String(id));
  
        return fazendaIds.some((idFazenda: string) => filteredFazendaIds.includes(idFazenda));
      });
  
      setContas(filteredContas);
  
    } catch (error) {
      console.error('Error fetching contas:', error);
    }
  };   
   
  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === '' || search.trim().length >= 3) {
      fetchContas(search.trim());
    } else {
      setAlerta('O termo de pesquisa deve ter pelo menos 3 caracteres.');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === '') {
      fetchContas('');
    }
  };

  useEffect(() => {
    fetchContas();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
  
    if (name === 'cpf_segundo_titular' || name === 'cpf_titular') {
      const unformattedCPF = value;
      const formattedCPF = unformattedCPF.replace(/[^0-9]/g, '');
  
      const maxLength = 11;
      const truncatedCPF = formattedCPF.slice(0, maxLength);
  
      const maskedCPF = truncatedCPF.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        '$1.$2.$3-$4'
      );
  
      setNewConta({ ...newConta, [name]: maskedCPF });
    } else {
      setNewConta({ ...newConta, [name]: value });
    }
  };  
  
  const handleFazendaChange = (selectedOptions: any) => {
    
    const selectedFazendaIds = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
  
    setNewConta(prevState => ({
      ...prevState,
      fazendas: selectedFazendaIds, 
    }));
  
    setSelectedFazendas(selectedOptions); 
  };

  const handleSubmit = async () => {
    setAlerta('');

    if (!newConta.descricao) {
      setAlerta('O campo Descrição é obrigatório.');
      return;
    }

    try {
      let result: any;
      if (isEditing) {
        result = await updateConta(newConta);
      } else {
        result = await createConta(newConta);
      }

      if (result.status === 'error') {
        setAlerta(result.messages[0]);
      } else {

        fetchContas('');

        /* if (isEditing) {

          setContas(contas.map(conta => (conta.id === newConta.id ? result : conta)));
        } else {
          setContas([...contas, result]);
        } */

        handleClose();
        setAlerta(isEditing ? 'Conta Bancária atualizada com sucesso.' : 'Conta Bancária cadastrada com sucesso.');
      }
    } catch (error) {
      setAlerta('Não foi possível realizar esta operação.');
    }
  };

  useEffect(() => {
   
    const segundoTitularInputs = document.querySelectorAll<HTMLInputElement>('[name="nome_segundo_titular"], [name="cpf_segundo_titular"]');
    if (newConta.tipo_conta === 'individual') {
      segundoTitularInputs.forEach(input => input.setAttribute('disabled', 'disabled'));
    } else {
      segundoTitularInputs.forEach(input => input.removeAttribute('disabled'));
    }
  }, [newConta.tipo_conta]);

  return (
    <>
      <HeaderComponent />
      <div className='page-content'>
        <MenuComponent />
        <div className='content-wrapper'>
          <div className='content-inner'>
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a onClick={() => navigate('/')} className="breadcrumb-item">
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">Contas Bancárias</span>
                  </div>
                  <a href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more"></i></a>
                </div>
              </div>
            </div>
            <div className='content'>
              <div className='card'>
                <div className="card-header">
                  <h5 className="card-title d-flex float-left">
                    <Button className='bt-1-ico' variant="indigo" onClick={add}><IoAddCircleOutline className='m-0-auto fs-2em' /></Button>
                    <span className='fs-1-5em pl-2'>Contas Bancárias</span>
                  </h5>
                  <div className='float-right'>
                    <form onSubmit={handleSearchSubmit}>
                      <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Pesquisar por descrição ou nome do titular..."
                          value={search}
                          onChange={handleSearchChange}
                        />
                        <div className="form-control-feedback form-control-feedback-lg pt-0">
                          <button type="submit" className="btn btn-link p-0">
                            <IoSearch className='fs-1-7em' />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='card-body'>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style={{ width: '80px' }}>#</th>
                          <th>Descrição</th>
                          <th>Nome Titular</th>
                          <th>CPF Titular</th>
                          <th>Saldo Atual</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contas.map(conta => (
                          <tr key={conta.id} onClick={() => edit(conta.id)} className='cursor-pointer'>
                            <td>{conta.id}</td>
                            <td>{conta.descricao}</td>
                            <td>{conta.nome_titular}</td>
                            <td>{conta.cpf_titular}</td>
                            <td>{conta.saldo_atual}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{isEditing ? 'Editar Conta Bancária' : 'Nova Conta Bancária'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label>Descrição: </label>
                <input type="text" className="form-control" placeholder="Descrição" name="descricao" value={newConta.descricao} onChange={handleInputChange} />
              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group">
                <label>Código do Banco: </label>
                <input type="text" className="form-control" placeholder="Código do Banco" name="codigo_banco" value={newConta.codigo_banco} onChange={handleInputChange} />
              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group">
                <label>Agência: </label>
                <input type="text" className="form-control" placeholder="Agência" name="agencia" value={newConta.agencia} onChange={handleInputChange} />
              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group">
                <label>Conta: </label>
                <input type="text" className="form-control" placeholder="Conta" name="conta" value={newConta.conta} onChange={handleInputChange} />
              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group">
                <label>Saldo inicial: </label>
                <InputDecimal2Component placeholder="0,00" value={newConta.saldo_inicial} name="saldo_inicial" onChange={handleInputChange} min="0" step="0,01" />        
              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group">
                <label>Saldo atual: </label>
                <InputDecimal2Component placeholder="0,00" value={newConta.saldo_atual} name="saldo_atual" onChange={handleInputChange} min="0" step="0,01" />        
              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group">
                <label>Tipo de conta: </label>
                <select
                      className='form-control'
                      name="tipo_conta"
                      value={newConta.tipo_conta}
                      onChange={handleInputChange}
                    >
                  <option value="individual">Individual</option>
                  <option value="conjunta">Conjunta</option>
                </select>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <label>Selecione uma ou várias fazendas: </label>
                <Select 
                  options={options} 
                  isMulti 
                  placeholder='Selecione'
                  name='fazendas' 
                  value={selectedFazendas}
                  onChange={(selectedOptions) => handleFazendaChange(selectedOptions)} 
                />
              </div>
            </Col>
            <Col lg={8}>
              <div className="form-group">
                <label>Nome do Titular: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nome do Titular"
                  name="nome_titular"
                  value={newConta.nome_titular}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={4}>
              <div className="form-group">
                <label>CPF do Titular: </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="___.___.___-__"
                  name="cpf_titular"
                  value={newConta.cpf_titular}
                  maxLength={14}
                  onChange={handleInputChange}
                  onKeyUp={(e) => {
                    if (/[^0-9\.\/]/g.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </Col>
            
            {newConta.tipo_conta !== 'individual' && (
              <Col lg={8}>
                <div className="form-group">
                  <label>Nome do segundo Titular: </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nome do segundo Titular"
                    name="nome_segundo_titular"
                    value={newConta.nome_segundo_titular || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            )}

            {newConta.tipo_conta !== 'individual' && (
              <Col lg={4}>
                <div className="form-group">
                  <label>CPF do segundo Titular: </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="___.___.___-__"
                    name="cpf_segundo_titular"
                    value={newConta.cpf_segundo_titular || ''}
                    maxLength={14}
                    onChange={handleInputChange}
                    onKeyUp={(e) => {
                      if (/[^0-9\.\/]/g.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </Col>
            )}

          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEditing ? 'Atualizar' : 'Salvar'}
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer 
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
}

export default ContasBancariasPage;